import React, { useState, useEffect } from 'react';


/**
 * GameWonTimer component
 * 
 * When the user wins the game, this component will be displayed to show the user.
 * It will display a message to the user and a countdown timer till the end of the game.
 * 
 */

export default function GameWonTimer() {
    const [timeRemaining, setTimeRemaining] = useState({ hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        // Function to calculate remaining time
        const calculateTimeRemaining = () => {
            const now = new Date();
            const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
            const diff = endOfDay - now;

            const hours = Math.floor(diff / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);

            setTimeRemaining({ hours, minutes, seconds });
        };

        // Initial calculation
        calculateTimeRemaining();

        // Update every second
        const interval = setInterval(calculateTimeRemaining, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <p className="text-lg md:text-3xl text-bold text-center text-emerald-500">
                Siguiente palabra del día en {timeRemaining.hours} horas, {timeRemaining.minutes} minutos y {timeRemaining.seconds} segundos.
            </p>
        </div>
    );
}
