import * as React from 'react';

import { FiMessageSquare } from "react-icons/fi";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { HiMiniChatBubbleBottomCenter } from "react-icons/hi2";


import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import ListDivider from '@mui/joy/ListDivider';
import Input from '@mui/joy/Input';
import { Box, Stack } from '@mui/joy';

import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

import { CgRename } from "react-icons/cg";
import { MdDeleteOutline } from "react-icons/md";

import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';

// Import chat-bubble-bottom-center from heroicons

import axios from 'axios';

import '../index.css';

import { UserContext } from '../pages/Focus';
import { MainUserContext } from '../App';
import { Tooltip } from '@mui/material';

import { motion } from "framer-motion";


function RenameChatModal({isOpen, setIsOpen, chatId}) {

    const [newChatName, setNewChatName] = React.useState('');
    
    const renameChat = ( newName ) => {
        axios.post(process.env.REACT_APP_API_URL + '/api/chats/rename', {
            conversation_id: chatId,
            conversation_name: newName
        }).then(response => {
        }).catch(error => {
        });
    }

    return (
        <React.Fragment>
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Sheet
            variant="outlined"
            sx={{ width: 800, borderRadius: 'md', p: 3, boxShadow: 'lg' }}
            >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                sx={{ fontWeight: 'lg', mb: 1, textAlign: 'center' }}
            >
                Rename chat
            </Typography>
            <form
                onSubmit={(e) => {
                e.preventDefault();
                renameChat(newChatName);
                setNewChatName('');
                setIsOpen(false);
            }}>
                <Input required
                    placeholder="New chat name"
                    value={newChatName}
                    onChange={(e) => setNewChatName(e.target.value)}
                    sx={{ width: '100%', mb: 2 }}
                />
            </form>
            </Sheet>
        </Modal>
        </React.Fragment>
    );
}

function ChatButtonMenu( {setIsOpen, chatId} ) {

    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { setMessages } = React.useContext(UserContext);
    const { setCurrentChatId} = React.useContext(UserContext);
    const deleteChat = () => {
        setMessages([]);
        setCurrentChatId(null);
        axios.post(process.env.REACT_APP_API_URL + '/api/chats/delete', {
            conversation_id: chatId
        }).then(response => {
        }).catch(error => {
        });
    }

    return (
        <Dropdown>
            <MenuButton variant="plain">
                <EllipsisHorizontalIcon
                    className='w-5 h-5 cursor-pointer hover:bg-white rounded-md'
                />
            </MenuButton>
            <Menu sx={{zIndex: '9999'}}>
                <MenuItem onClick={() => setIsOpen(true)} disabled={messageIsLoading}>
                    <ListItemDecorator>
                        <CgRename/>
                    </ListItemDecorator>
                    <text
                        className='text-gray-500 text-sm font-semibold'
                    >
                        Rename
                    </text>
                </MenuItem>
                <ListDivider/>
                <MenuItem variant='soft' color='danger' disabled={messageIsLoading}
                    onClick={() => deleteChat()}>
                    <ListItemDecorator>
                        <MdDeleteOutline/>
                    </ListItemDecorator>
                    <text
                        className='text-red-500 text-sm font-semibold'
                    >
                        Delete
                    </text>
                </MenuItem>
            </Menu>
        </Dropdown>
    )
}

function SnackbarWithDecorators({alertOpen, setAlertOpen}) {
  
    return (
      <React.Fragment>
        <Snackbar
          variant="soft"
          autoHideDuration={2000}
          color="success"
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
          endDecorator={
            <Button
              onClick={() => setAlertOpen(false)}
              size="sm"
              variant="soft"
              color="success"
            >
              Dismiss
            </Button>
          }
        >
          Chat selected successfully!
        </Snackbar>
      </React.Fragment>
    );
  }

function ChatButton({chat, chatName, chatId, setCurrentChatName}) {
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { currentChatId, setCurrentChatId, setSparkaiSidebarOpen } = React.useContext(UserContext);
    const [ buttonStyle, setButtonStyle ] = React.useState("bg-white");

    const [ chatHour, setChatHour ] = React.useState('');
    const [ chatDay, setChatDay ] = React.useState('');

    React.useEffect(() => {
        const date = new Date(chat.updated_at);
        // Get hour in 12-hour format
        const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        setChatHour((hours < 10 ? '0' : '') + hours + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ' ' + (date.getHours() >= 12 ? 'PM' : 'AM'));
        // If the chat was started less than 8 days ago, show the days since the chat started
        const today = new Date();
        const diffTime = Math.abs(today - date);
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        // If the chat was started today, show today
        if (diffDays < 1) {
            setChatDay('Today');
        }
        else if (diffDays < 8) {
            setChatDay(Math.ceil(diffDays) + ' days ago');
        }
        // If the chat was started more than 8 days ago, show the date
        else {
            setChatDay(date.toDateString());
        }
    }, [chat]);
    
    React.useEffect(() => {
        if (chatId === currentChatId) {
            setButtonStyle("bg-gray-200");
        } else {
            setButtonStyle("bg-white");
        }
    }, [currentChatId]);

    const setChatName = (newChatName, newChatId) => {
        if (newChatId === currentChatId) {
            return;
        }
        setCurrentChatName(newChatName);
        setCurrentChatId(newChatId);
        if (window.innerWidth > 768) {
            setAlertOpen(true);
        } else {
            setSparkaiSidebarOpen(false);
        }
    }
    return (
        <>
        <Tooltip title={chatName} placement="right">
        <button 
            className={`w-[95%] min-h-16 rounded-md justify-between items-center ${buttonStyle} hover:bg-gray-100`}
        onClick={() => setChatName(chatName, chatId)} disabled={messageIsLoading}>
            <div 
                className="flex flex-row justify-between items-center p-1"
            >
                <div
                    className="flex flex-row items-center gap-2 max-w-[80%]"
                >
                    <div>
                        <HiMiniChatBubbleBottomCenter/>
                    </div>
                    <text
                        className="text-xs text-gray-800 font-semibold flex-grow overflow-x-hidden text-overflow-ellipsis whitespace-nowrap justify-start"
                        style={{flexGrow: 1, overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                        >
                        {chatName}
                    </text>
                </div>
                <ChatButtonMenu setIsOpen={setIsOpen} chatId={chatId}/>
                <RenameChatModal isOpen={isOpen} setIsOpen={setIsOpen} chatId={chatId}/>
            </div>
            <div
                className="flex flex-row justify-between items-center gap-3 px-2"
            >
                <text
                    className="text-xs text-gray-500"
                    >
                    {chatDay}
                </text>
                <text
                    className="text-xs text-gray-500"
                    >
                    {chatHour}
                </text>
            </div>
        </button>
        </Tooltip>
        </>
    )
}

export default function ChatList({filterName, setCurrentChatName, hidden, setSidebarOpen}) {

    const { chats, setChats } = React.useContext(UserContext);
    const { currentChatId, setCurrentChatId } = React.useContext(UserContext);
    const { userId, setUserId } = React.useContext(MainUserContext);
    const { isOpen, setIsOpen } = React.useContext(UserContext);


    
    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/focus/chat/' + userId)
        .then(response => {
            setChats(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    });

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.25 }}
            key={isOpen}
            className={`flex flex-col flex-grow overflow-y-auto overflow-x-hidden justify-start items-center max-h-[35vh] gap-1 ${hidden ? 'hidden' : ''}`}
        >
            {chats.map(function(chat){
                if (chat.conversation_name.toString().toLowerCase().includes(filterName.toString().toLowerCase())){
                    return (
                        <>
                            <ChatButton key={chat.conversation_id} chat={chat} chatName={chat.conversation_name} chatId={chat.conversation_id} setCurrentChatName={setCurrentChatName} setCurrentChatId={setCurrentChatId}/>
                            <hr className="w-11/12 border-gray-300"/>
                        </>
                    )
                }
            })}
        </motion.div>
    )
}