export default function HelixLoader() {
    return (
        <div class="container scale-[0.65]">
          <div class="slice"></div>
          <div class="slice"></div>
          <div class="slice"></div>
          <div class="slice"></div>
          <div class="slice"></div>
          <div class="slice"></div>
        </div>
    )
}
