import * as React from 'react';
import { useEffect } from "react";
import axios from "axios";
import { MainUserContext } from "../App";
import { useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Helmet } from 'react-helmet';
import RightSideMenu from "../common_components/RightSideMenu";

import FeedbackButtons from "../common_components/FeedbackButtons";
import ChatContent from "../components/ChatContent";

import SparkaiSidebar from "../components/SparkaiSidebar";
import { checkAuthStatus, fetchUserData } from '../services/authService';

export const UserContext = React.createContext(null);

const messageLoadingDetailsList = [
    "Translating text",
    "Reading the whole paper",
    "Gathering relevant information",
    "Sending information",
    "Synthesizing the answer",
    "Searching for your ideal paper",
];

export default function Chat({openTutorial}) {
    const { userId } = React.useContext(MainUserContext);
    const [searchParams] = useSearchParams();
    const chatId = searchParams.get('chat_id');

    const { setUser } = React.useContext(MainUserContext);
    const { setUserId } = React.useContext(MainUserContext);
    const { setLoggedIn } = React.useContext(MainUserContext);
    const { setEmail } = React.useContext(MainUserContext);

    const [isOpen, setIsOpen] = React.useState(true);
    const [enableChatInput, setEnableChatInput] = React.useState(false);
    const [currentChatId, setCurrentChatId] = React.useState(null);

    const [messageIsLoading, setMessageIsLoading] = React.useState(false);
    const [messageIsLoadingDetails, setMessageIsLoadingDetails] = React.useState("Searching for your ideal paper");

    const [messages, setMessages] = React.useState([]);
    const [chats, setChats] = React.useState([]);

    const [openSources, setOpenSources] = React.useState(false);
    const [sources, setSources] = React.useState([]);
    const [ newSourcesNotification, setNewSourcesNotification ] = React.useState(false);

    const [ sourcesDrawerOpen, setSourcesDrawerOpen ] = React.useState(false);
    const [ messageIdForSources, setMessageIdForSources ] = React.useState(null);

    const [ sparkaiSidebarOpen, setSparkaiSidebarOpen ] = React.useState(true);
    const [ marginLeft, setMarginLeft ] = React.useState(100);

    const [ showSurvey, setShowSurvey ] = React.useState(false);

    const [ model, setModel ] = React.useState('smart');

    const [isValidatingChat, setIsValidatingChat] = React.useState(true);

    React.useEffect(() => {
        const validateChat = async () => {
            if (chatId && userId) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/chat/check_conversation/${chatId}&${userId}`);
                    if (!response.data.isOwner) {
                        window.location.replace('/chat');
                    }
                } catch (error) {
                    console.error('Error validating chat ownership:', error);
                    window.location.replace('/chat');
                }
            }
            setIsValidatingChat(false);
        };

        validateChat();
    }, [chatId, userId]);

    React.useEffect(() => {
        if (window.innerWidth < 768) {
            setSparkaiSidebarOpen(false);
        }
    }, []);

    /**
     * If messageIsLoading is true, messageIsLoadingDetails will be updated every 2 seconds,
     * cycling through the messageLoadingDetailsList array in loop.
     */

    React.useEffect(() => {
        let interval;
        if (messageIsLoading) {
            let i = 0;
            interval = setInterval(() => {
                setMessageIsLoadingDetails(messageLoadingDetailsList[i]);
                i = (i + 1) % messageLoadingDetailsList.length;
            }, 3500); // Run every 2 seconds
        }
        return () => clearInterval(interval); // Clear the interval when the component unmounts
    }, [messageIsLoading]);

    React.useEffect(() => {
        const validateAuth = async () => {
            try {
                const user = await checkAuthStatus();
                
                if (user) {
                    const userData = await fetchUserData(user.mail);
                    setUser(userData);
                    setUserId(userData.user_id);
                    setLoggedIn(true);
                    setEmail(userData.mail);
                } else {
                    handleUnauthorized();
                }
            } catch (error) {
                handleUnauthorized();
            }
        };

        const handleUnauthorized = () => {
            setLoggedIn(false);
            window.location.replace('https://ederspark.com/?page=platform');
        };

        validateAuth();
    }, [setUser, setUserId, setLoggedIn, setEmail]);

    // Check if window is smaller than 768px
    const [buttonsDirection, setButtonsDirection] = React.useState('row');

    React.useEffect(() => {
        console.log('window.innerWidth:', window.innerWidth);
        if (window.innerWidth < 768) {
            setButtonsDirection('col');
        }
        else {
            setButtonsDirection('row');
        }
    }, [window.innerWidth]);


    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/polls/' + userId)
        .then(response => {
            setShowSurvey(!response.data.user_has_answered);
        })
        .catch(error => {
            console.error('Error fetching survey data:', error);
        }
    );
    }, [userId]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/sources/' + currentChatId)
        .then(response => {
            setSources(response.data);
        })
        .catch(error => {
        });
    }, [openSources, currentChatId, messageIsLoading]);

    const [currentChatName, setCurrentChatName] = React.useState('New chat');

    // Effect to handle chat_id from URL
    React.useEffect(() => {
        if (chatId) {
            setCurrentChatId(chatId);
            // Fetch chat data based on ID
            axios.get(`${process.env.REACT_APP_API_URL}/api/chats/${chatId}`)
                .then(response => {
                    if (response.data.length > 0) {
                        setCurrentChatName(response.data[0].conversation_name);
                    }
                })
                .catch(error => {
                    console.error('Error fetching chat:', error);
                });
        }
    }, [chatId]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            toast(
                <div className="bg-gradient-to-r from-green-50 to-emerald-50 rounded-lg shadow-lg border border-green-100 p-8">
                    <div className="flex flex-col space-y-4">
                        <div className="flex items-center justify-center">
                            <span className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-600 to-emerald-600 px-4">
                                ✨ Important Announcement ✨
                            </span>
                        </div>
                        <p className="text-gray-700 leading-relaxed text-md">
                            Thank you for being part of the <span className="font-semibold text-emerald-600">SparkAI</span> journey! 
                            We're thrilled to announce that after a successful beta testing phase, 
                            SparkAI will be transitioning to a premium service starting next month.
                            Every user registered before this date, will have access to the platform for free for a couple of months.
                            <span className="block mt-3 font-medium text-green-600 text-center">
                                Stay tuned for exciting new features and subscription details.
                            </span>
                        </p>
                    </div>
                </div>, 
                {
                    position: "bottom-center",
                    hideProgressBar: false,
                    autoClose: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    className: 'w-auto mx-auto',
                    style: { 
                        background: 'transparent',
                        boxShadow: 'none',
                        minWidth: '60vw'
                    }
                }
            );
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    if (isValidatingChat) {
        return null; // or a loading spinner
    }

    return (
        <UserContext.Provider value={{ messages, setMessages, chats, setChats, messageIsLoading, setMessageIsLoading, messageIsLoadingDetails, setMessageIsLoadingDetails, currentChatId, setCurrentChatId, currentChatName, setCurrentChatName, sources, setSources, openSources, setOpenSources, newSourcesNotification, setNewSourcesNotification, model, setModel, isOpen, setIsOpen, enableChatInput, setEnableChatInput, sourcesDrawerOpen, setSourcesDrawerOpen, messageIdForSources, setMessageIdForSources, sparkaiSidebarOpen, setSparkaiSidebarOpen, marginLeft, setMarginLeft }}>
            <Helmet>
                <title>SparkAI Chat</title>
            </Helmet>
            <ToastContainer />
            <SparkaiSidebar setSidebarOpen={setSparkaiSidebarOpen} sidebarOpen={sparkaiSidebarOpen} />
            <ChatContent />
        </UserContext.Provider>
    )
}