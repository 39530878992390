import '../index.css';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import React from "react";
import { motion } from "framer-motion";

import { UserContext } from "../pages/Focus";


export default function ChatSearch({setFilterName, hidden}) {

    const { isOpen, setIsOpen } = React.useContext(UserContext);

    return (
        <div 
            className="flex justify-center items-center"
        >
            <motion.input
                hidden={hidden}
                key={isOpen}
                type="text"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                whileHover={{ backgroundColor: '#ffffff' }} // white on hover
                whileFocus={{ backgroundColor: '#ffffff' }} // white on focus
                transition={{ duration: 0.2 }}
                className="w-11/12 h-10 px-3 border-y border-gray-300 bg-gray-100 focus:outline-none" 
                placeholder="Search a chat" onChange={(e) => setFilterName(e.target.value)}/>
            <MagnifyingGlassIcon
                className={`w-5 h-5 text-gray-500 z-50 absolute
                            right-${hidden ? '7' : '4'}
                            cursor-text
                `}
            />
        </div>
    )
}