import { Skeleton, Stack, Typography } from '@mui/joy';
import '../index.css';
import * as React from 'react';
import axios from 'axios';

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'

import { VscReferences } from "react-icons/vsc";
import { CircularProgress } from "@mui/joy";
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { DocumentCheckIcon } from '@heroicons/react/20/solid';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/20/solid';
import { motion } from 'framer-motion';
import SparkAILogo from '../assets/sparkai_new_only_logo.png';
import { IoSend } from "react-icons/io5";
import HelixLoader from '../common_components/HelixLoader.jsx';

import { UserContext } from '../pages/Chat';

import '../index.css';
import { MainUserContext } from '../App';

import { replaceCustomMarkdown } from '../utils/utils';

import { calcMaxInputLength } from '../utils/utils';

import { createChatName, createConversation, SparkAICall } from './helpers';
import 'ldrs/helix'

import { Rating } from '@mui/material';
import confetti from 'canvas-confetti';
import ResponseRenderer from './ResponseRenderer';


function ChatMessage({ role_id, content, lastSecondsElapsed, messageId, lastMessageId }) {
    const displayRole = role_id === 1 ? '' : 'SparkAI';
    const roleClass = role_id === 1 ? 'message-user' : 'message-bot';
    const containerClass = role_id === 1 ? 'message-container-user' : 'message-container-bot';
    const showTime = role_id === 2 && lastSecondsElapsed;

    const [ contentToDisplay, setContentToDisplay ] = React.useState(content);
    const { setMessageIdForSources, setSourcesDrawerOpen, sourcesDrawerOpen } = React.useContext(UserContext);
    const [rating, setRating] = React.useState(0);
    const [citations, setCitations] = React.useState(null);

    // Fetch citations from the api (api/messages/citations/:message_id)
    React.useEffect(() => {
        if (messageId) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/messages/citations/${messageId}`)
                .then(response => {
                    setCitations(response.data);
                })
                .catch(error => {
                    console.error('Error fetching citations:', error);
                });
        }
    }, [messageId]);


    React.useEffect(() => {
        if (messageId) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/messages/rating/${messageId}`)
                .then(response => {
                    if (response.data.status === "success") {
                        setRating(response.data.rating || 0);
                    }
                })
                .catch(error => {
                    console.error('Error fetching rating:', error);
                });
        }
    }, [messageId]);

    const handleOpenReferences = () => {
        console.log('Opening references for message:', lastMessageId);
        setMessageIdForSources(lastMessageId);
        if (sourcesDrawerOpen) {
            setSourcesDrawerOpen(false);
        } else
            setSourcesDrawerOpen(true);
    }

    const handleRatingChange = (event, newValue) => {
        setRating(newValue);
        // Make API request to store rating
        axios.post(process.env.REACT_APP_API_URL + '/api/messages/rate', {
            message_id: messageId,
            rating: newValue
        });

        // Show confetti animation for high ratings
        if (newValue >= 4) {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }
    };

    return (
        <div className={`${containerClass} relative`}>
            {showTime ? (
                <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        {displayRole}
                    </Typography>
                    <Typography level="body-sm" sx={{ whiteSpace: 'nowrap' }}>
                        {Math.round(lastSecondsElapsed)} secs
                    </Typography>
                </Stack>
            ) : (
                <div
                    className={`flex absolute top-6 
                            ${role_id === 2 ? `left-[-30px]` : ` 
                            right-[-60px]`}`}
                >
                    {displayRole === 'SparkAI' ? (
                        <img
                            className="w-[22px] h-[24px] hidden md:block"
                            src={SparkAILogo} alt="SparkAI_logo"
                        />
                        ) : (
                            <p 
                                className="text-sm md:text-base text-gray-700 text-justify font-semibold hidden md:block"
                            >
                                {displayRole}
                            </p>
                        )
                    }
                </div>
            )}
            <div className={`${roleClass} w-full relative flex flex-col gap-2 relative`}>
                {role_id === 2 ? (
                    <ResponseRenderer 
                        response={content} 
                        citations={citations || {}}
                    />
                ) : (
                    <Markdown
                        remarkPlugins={[[remarkGfm], [remarkMath]]} 
                        rehypePlugins={[[rehypeKatex], [rehypeRaw]]}
                        className="text-lg md:text-base text-gray-800 md:leading-10"
                    >
                        {content}
                    </Markdown>
                )}
                {role_id === 2 &&
                    <div className="flex flex-col gap-2">
                        <button
                            className="hidden sm:flex flex-row gap-2 justify-start items-center text-sm text-gray-700 font-semibold hover:bg-emerald-100 bg-emerald-200 rounded-lg px-3 py-2 w-fit hover:scale-[1.02] transition-all duration-300"
                            onClick={handleOpenReferences}
                        >
                            <p>
                                More related papers
                            </p>
                            <VscReferences
                                className="w-5 h-5 text-gray-700 cursor-pointer"
                            />
                        </button>
                        <div className="flex flex-row gap-2 justify-start items-center">
                            <p
                                className="text-sm text-gray-700 font-semibold"
                            >
                                Is this answer helpful?
                            </p>
                            <Rating
                                name="message-rating"
                                value={rating}
                                precision={1}
                                size="medium"
                                onChange={handleRatingChange}
                                sx={{
                                    '& .MuiRating-iconFilled': {
                                        color: 'lightgreen'
                                    },
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

function MessagesList( {messages} ) {
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const {messageIsLoadingDetails, setMessageIsLoadingDetails} = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);

    const [secondsElapsed, setSecondsElapsed] = React.useState(0);
    const [lastSecondsElapsed, setLastSecondsElapsed] = React.useState(null);

    React.useEffect(() => {
        let interval;
        
        if (messageIsLoading) {
        // Start the interval to update time every 100ms
        interval = setInterval(() => {
            setSecondsElapsed(prev => prev + 0.1); // Increment by 0.1 every 100ms
        }, 100);
        } else {
            // If not processing, clear the timer
            setLastSecondsElapsed(secondsElapsed); // Save last time when processing stops
            setSecondsElapsed(0); // Reset timer when processing stops
        }

        // Clear interval when component unmounts or stops processing
        return () => clearInterval(interval);
    },  [messageIsLoading, currentChatId]);


    return (
        <div
            className='flex flex-col w-full h-full justify-start items-center overflow-y-auto mt-[50px] md:mt-0'
        >
            <div className="flex flex-col gap-5 w-full justify-start items-center mb-[90px]">
                <div className="h-full w-full overflow-x-hidden justify-center flex pt-28">
                    <div className="flex flex-col gap-10 w-11/12 sm:w-[58%] bg-yellow">
                        {messages.map((chat, index) => (
                            <ChatMessage key={index} role_id={chat.role_id} content={chat.content} lastSecondsElapsed={lastSecondsElapsed} messageId={chat.message_id} lastMessageId={messages[index - 1]?.message_id} />
                        ))}
                    </div>
                </div>
                { messageIsLoading &&
                <div
                    className="flex flex-row mt-8 justify-between items-center w-11/12 sm:w-[58%] mb-20"
                >
                    <div 
                        className="flex flex-row gap-4 justify-start items-center w-full px-2 sm:px-0"
                    >
                        <HelixLoader/>
                        <motion.div
                            key={messageIsLoadingDetails}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5 }}
                            className='text-base md:text-base text-gray-700 text-justify md:leading-10'
                            >
                            <motion.p
                                key={messageIsLoadingDetails}
                                initial={{ opacity: 1 }}
                                animate={{ opacity: [1, 0.5, 1] }}
                                transition={{ duration: 3, repeat: Infinity }}
                                >
                                {messageIsLoadingDetails}
                            </motion.p>
                        </motion.div>
                    </div>
                    <p
                        className="text-xs md:text-sm text-gray-700 text-justify md:leading-10 whitespace-nowrap"
                        >
                        {secondsElapsed.toFixed(1)} secs
                    </p>
                </div>
                }
            </div>
        </div>
    )
}

function NoMessages( {createChat} ) {
    const [firstMessage, setFirstMessage] = React.useState('');
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);

    // Cards hovers
    const [firstCardHover, setFirstCardHover] = React.useState(false);
    const [secondCardHover, setSecondCardHover] = React.useState(false);
    const [thirdCardHover, setThirdCardHover] = React.useState(false);

    const [textareaHeight, setTextareaHeight] = React.useState(50); // Default height of the textarea
    const textareaRef = React.useRef(null);

    React.useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height to calculate correct scrollHeight
            const newHeight = textareaRef.current.scrollHeight;
            // Cap the height at 150px
            if (newHeight > 125) {
                textareaRef.current.style.height = "125px"; // Maximum height
                textareaRef.current.style.overflowY = "auto"; // Add scrollbar when exceeding max height
                textareaRef.current.style.borderRadius = "25px"; // Round the corners
                setTextareaHeight(150); // Update height state for animation
            } else {
                textareaRef.current.style.height = `${newHeight}px`; // Allow auto-resizing below 150px
                textareaRef.current.style.overflowY = "hidden"; // Hide scrollbar when below max height
                textareaRef.current.style.borderRadius = "25px"; // Round the corners
                setTextareaHeight(newHeight); // Update height state for animation
            }
        }
    }, [firstMessage]);

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent new line on Enter
            await createChat({firstMessage: firstMessage, currentChatId: currentChatId}); // Trigger form submission
        }
    };

    return (
        <div 
            className="first-step flex flex-col justify-center items-center rounded-lg gap-10 p-4 h-[400px] md:h-[400px] w-[400px] md:w-[700px] md:max-h-[67%]"
        >
                <div 
                    className="flex flex-col justify-center items-center gap-3"
                >
                    <img 
                        className='w-6 md:w-8 h-6 md:h-8'
                        src={SparkAILogo} alt="SparkAI_logo"
                    />
                    <text
                        className="text-2xl md:text-3xl font-semibold text-center text-gray-700"
                    >
                        Ask anything, save time
                    </text>
                </div>
                <div
                    className="w-full flex flex-row gap-3 justify-center items-center"
                >
                    <div
                        className="hello-feature-card"
                        onMouseEnter={() => setFirstCardHover(true)}
                        onMouseLeave={() => setFirstCardHover(false)}
                    >
                        {firstCardHover ?
                        <text
                            className="hello-feature-card-text"
                        >
                            Our database is being updated every day
                        </text>
                        :
                        <>
                        <ArrowPathIcon 
                            className="hello-feature-card-icon"
                            />
                        <text
                            className="hello-feature-card-text"
                            >
                            Up-to-date scientific literature
                        </text>
                        </>
                        }
                    </div>
                    <div 
                        className="hello-feature-card"
                        onMouseEnter={() => setSecondCardHover(true)}
                        onMouseLeave={() => setSecondCardHover(false)}
                    >
                        {secondCardHover ?
                        <text
                            className="hello-feature-card-text"
                        >
                            SparkAI will cite and reference all information found
                        </text>
                        :
                        <>
                        <DocumentCheckIcon
                            className="hello-feature-card-icon"
                        />
                        <text
                            className="hello-feature-card-text"
                        >
                            Fully referenced citations
                        </text>
                        </>
                        }
                    </div>
                    <div
                        className="hello-feature-card"
                        onMouseEnter={() => setThirdCardHover(true)}
                        onMouseLeave={() => setThirdCardHover(false)}
                    >
                        {thirdCardHover ?
                        <text
                            className="hello-feature-card-text"
                        >
                            Enjoy a natural and interactive conversation 
                        </text>
                        :
                        <>
                        <ChatBubbleLeftRightIcon
                        className="hello-feature-card-icon"
                        />
                        <text
                        className="hello-feature-card-text"
                        >
                            Organic conversations
                        </text>
                        </>
                        }
                    </div>
                </div>
                <form 
                    onSubmit={async (e) => {
                        e.preventDefault();
                        await createChat({firstMessage: firstMessage, currentChatId: currentChatId});
                    }}
                    className="flex flex-row justify-center items-center w-full relative"
                >
                    <motion.textarea required
                        ref={textareaRef}
                        className="input-chat w-[100%]"
                        value={firstMessage}
                        onChange={(e) => setFirstMessage(e.target.value)}
                        placeholder="Enter your question here" 
                        onKeyDown={handleKeyDown} // Listen for Enter key
                        animate={{ height: textareaHeight }} // Animate height dynamically
                        disabled={messageIsLoading}
                    />
                    <div
                        className="flex justify-center items-center absolute right-3 bottom-3"
                    >
                        <button
                            className="input-button"
                            type='submit'
                            disabled={messageIsLoading}>
                                {messageIsLoading ? 
                                <CircularProgress 
                                variant='plain'
                                thickness={1}
                                size="sm"
                                /> : 
                                <IoSend
                                    className="text-gray-600 w-6 h-6"
                                />
                            }
                        </button>
                    </div>
                </form>
        </div>
    );
}

export default function ChatBot( ) {
    const { messages, setMessages } = React.useContext(UserContext);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const {setMessageIsLoadingDetails } = React.useContext(UserContext);
    const { currentChatId, setCurrentChatId } = React.useContext(UserContext);
    const { currentChatName, setCurrentChatName } = React.useContext(UserContext);
    const { userId } = React.useContext(MainUserContext);
    const { setEnableChatInput } = React.useContext(UserContext);

    const { model } = React.useContext(UserContext);


    React.useEffect(() => {
        let interval;
      
        interval = setInterval(() => {
            console.log('Checking... Messages length:', messages.length);
        }, 3000); // Run every 5 seconds
      
        return () => clearInterval(interval); // Clear the interval when the component unmounts
      }, [messages, currentChatId]);

    const createChat = async ({ firstMessage }) => {
        
        if (messageIsLoading){
            return; // Prevent multiple submissions
        }

        // Validate message length
        if (calcMaxInputLength(model, firstMessage.length)) {
            alert(`Your message is too long. Please try again with a shorter message. Length: ${firstMessage.length}`);
            return;
        }

    
        try {
            // Step 1: Generate chat name
            const generatedChatNameResult = await createChatName(firstMessage);
            console.log('[CreateChat] Generated chat name:', generatedChatNameResult);
            const generatedChatName = generatedChatNameResult.chat_name;
            setCurrentChatName(generatedChatName);
            
            // Step 2: Update messages with the first message
            setMessages([{ role_id: 1, content: firstMessage }]);
            setMessageIsLoading(true); // Show loading state
    
            // Step 3: Create a new conversation
            const createConversationResult = await createConversation(userId, generatedChatName);
            console.log('[CreateChat] Created conversation:', createConversationResult);
            console.log('[CreateChat] Messages for conversation:', messages);
            const chatId = createConversationResult.chatId;
            console.log('[CreateChat] Chat ID:', chatId);
            setCurrentChatId(chatId);
    
            // Step 4: Make the SparkAI call
            const sparkaiCallResult = await SparkAICall(firstMessage, [{ role_id: 1, content: firstMessage }], model, chatId);
            console.log('[CreateChat] SparkAI call result:', sparkaiCallResult);

            if (sparkaiCallResult.error){
                console.log('[CreateChat] 1. SparkAI call error:', sparkaiCallResult.error);
                return;
            }
    
            // Update messages with SparkAI response
            setMessages(prevMessages => [
                ...prevMessages,
                { role_id: 2, content: sparkaiCallResult.text },
            ]);
        } catch (error) {
            console.error('[CreateChat] Error:', error);
            alert('An error occurred while processing your request. Please try again.');
        } finally {
            setMessageIsLoading(false); // Reset loading state
        }
    };
    

    const messagesLength = messages.length;
    React.useEffect(() => {

        if (messagesLength > 0) {
            setEnableChatInput(true);
        } else {
            setEnableChatInput(false);
        }
    }, [messages]);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
            .then((response) => {
                setMessages(response.data);
            })
            .catch((error) => {
                console.error('Error fetching messages:', error);
            });
    }, [currentChatId, messageIsLoading]);
    return (
        <>
        {messagesLength > 0 || messageIsLoading ? <MessagesList messages={messages} /> : <NoMessages createChat={createChat}/>}
        </>
    );
}