import { Box, Input } from '@mui/joy';
import React, { useEffect, useState, useRef } from 'react';
import { Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { UserContext } from '../pages/Focus';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft } from '@mui/icons-material';
import { ChevronRight } from '@mui/icons-material';


export default function FloatingChatBot() {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const { paper } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);
    const [isOpen, setIsOpen] = useState(true);

    const [messageIsLoading, setMessageIsLoading] = useState(false);

    const messagesEndRef = useRef(null);
    const chatRef = useRef(null);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/messages/' + currentChatId)
            .then(response => {
                setMessages(response.data);
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    }, [currentChatId]);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSendMessage = () => {
        if (newMessage.trim() !== "") {
            setMessageIsLoading(true);
            messages.push({ role_id: 1, content: newMessage });
            axios.post(process.env.REACT_APP_API_URL + '/api/qdrant/search/', {
                paper_id: paper.paper_id,
                query: newMessage
            }).then(response => {
                setNewMessage("");
                console.log('Response from Qdrant:', response.data);
                axios.post(process.env.REACT_APP_API_URL + '/api/ia/focus/call/', {
                    paper_title: paper.title,
                    paper_abstract: paper.abstract,
                    paper_authors: paper.authors,
                    chat_history: messages,
                    context: response.data.extracts,
                    user_query: newMessage
                }).then(response => {
                    messages.push({ role_id: 2, content: response.data.response });
                    axios.post(process.env.REACT_APP_API_URL + '/api/messages/', {
                        conversation_id: currentChatId,
                        role_id: 1,
                        message: newMessage
                    });
                    axios.post(process.env.REACT_APP_API_URL + '/api/messages/', {
                        conversation_id: currentChatId,
                        role_id: 2,
                        message: response.data.response
                    });
                    setMessageIsLoading(false);
                });
            });
        }
    };

    return (
        <div className="flex">
            <motion.div 
                className="fixed right-0 top-[60px] flex h-screen"
                initial={false}
                animate={{ 
                    width: isOpen 
                        ? window.innerWidth <= 768 
                            ? '100%' 
                            : '450px' 
                        : '40px' 
                }}
                transition={{ type: "tween", duration: 0.3 }}
            >
                <button 
                    onClick={() => setIsOpen(!isOpen)}
                    className={`p-1 rounded-full absolute z-50 ${
                        isOpen 
                            ? "left-[5px] top-[7px]" 
                            : window.innerWidth <= 768
                                ? "left-[-60px] top-[10px] w-[150px]"
                                : "left-[-80px] top-[10px] w-[200px]"
                    }`}
                >
                    {isOpen ? <ChevronRight className="h-8 w-8 hover:bg-gray-100" /> : (
                        <div className="rounded-full p-2 flex flex-row items-center gap-2 bg-purple-100 hover:bg-purple-200">
                            <span className="text-purple-900 text-sm font-semibold">
                                Open Chat
                            </span>
                            <ChevronLeft className="h-8 w-8" />
                        </div>
                    )}
                </button>

                <motion.div
                    className="w-full h-full rounded-l-lg bg-white border-l-2 border-t-[4px] border-purple-900 flex flex-col absolute right-0"
                    animate={{ 
                        x: isOpen 
                            ? 0 
                            : window.innerWidth <= 768 
                                ? window.innerWidth 
                                : 450 
                    }}
                    transition={{ type: "tween", duration: 0.3 }}
                >

                    {/* Chat Messages */}
                    <div className="flex-1 overflow-y-auto p-4 max-h-[82%] flex flex-col gap-2">
                        {messages.map((message, index) => (
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                key={index}
                                className={`text-gray-900 p-3 rounded-lg whitespace-pre-line
                                    ${message.role_id === 1 ? "self-end w-10/12" : "self-start w-full"} 
                                    ${message.role_id === 1 ? "bg-purple-100" : ""}`}
                            >
                                <ReactMarkdown className="leading-7 text-start">
                                    {message.content}
                                </ReactMarkdown>
                            </motion.div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>

                    {/* Chat Input */}
                    <div className="p-4 border-t bg-white">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleSendMessage();
                        }}>
                            <Input
                                placeholder="Type your message here"
                                required
                                variant="soft"
                                disabled={messageIsLoading}
                                endDecorator={
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        disabled={messageIsLoading}
                                        className="bg-purple-500 h-8 w-8 rounded-lg flex justify-center items-center hover:bg-purple-300 shadow-md"
                                        type="submit"
                                    >
                                        <ArrowRightIcon className="text-white h-6 w-6" />
                                    </motion.button>
                                }
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                sx={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                }}
                            />
                        </form>
                    </div>
                </motion.div>
            </motion.div>
        </div>
    );
}