import '../index.css';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import React from "react";
import { motion } from "framer-motion";

import { UserContext } from "../pages/Chat";


export default function ChatSearch({setFilterName, hidden}) {

    const { sparkaiSidebarOpen, setSparkaiSidebarOpen } = React.useContext(UserContext);

    return (
        <div 
            className="flex justify-center items-center mt-1"
        >
            <motion.input
                hidden={hidden}
                key={sparkaiSidebarOpen}
                type="text"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                whileHover={{ backgroundColor: '#ffffff' }} // white on hover
                whileFocus={{ backgroundColor: '#ffffff' }} // white on focus
                transition={{ duration: 0.2 }}
                className="w-11/12 h-8 px-3 border-y border-gray-300 bg-gray-100 focus:outline-none placeholder:text-gray-400 placeholder:text-sm" 
                placeholder="Search a chat" onChange={(e) => setFilterName(e.target.value)}/>
            <MagnifyingGlassIcon
                onClick={() => setSparkaiSidebarOpen(true)}
                className={`${hidden ? 'w-4 h-4' : 'w-5 h-5'} text-gray-500 z-50 absolute
                            ${hidden ? 'left-[26px]' : 'right-4'}
                            ${hidden ? 'cursor-pointer' : 'cursor-text'}
                        `}
            />
        </div>
    )
}