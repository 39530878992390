import React, { useState, useRef, useContext, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { submitQuery } from './utility/submitQuery';
import { LambdaContext } from '../pages/Lambda';

export default function LambdaInput({ submitted, setSubmitted }) {
    const [isFocused, setIsFocused] = useState(false);
    const [textareaHeight, setTextareaHeight] = useState(80); // Default height of the textarea
    const textareaRef = useRef(null);
    const { setIntent, setAnswer, setArticles, setFollowUpQuestions, inputValue, setInputValue, setIsLoading } = useContext(LambdaContext);

    const inputVariants = {
        default: {
            y: 25,
            transition: { duration: 0.3, type: "tween", ease: "easeInOut" },
        },
        submitted: {
            y: -275,
            transition: { duration: 0.5, type: "tween", ease: "easeInOut" },
        },
    };

    // Adjust height dynamically based on content
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height to calculate correct scrollHeight
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scrollHeight
            setTextareaHeight(textareaRef.current.scrollHeight); // Update height state for animation
        }
    }, [inputValue]); // Recalculate height whenever the input value changes

    const handleSubmit = async (e) => {
        e.preventDefault();

        await submitQuery(
            inputValue,
            setIntent,
            setAnswer,
            setArticles,
            setFollowUpQuestions,
            setSubmitted,
            setInputValue,
            setIsLoading
        );

        // Optional: blur the textarea after submission
        setTimeout(() => {
            if (textareaRef.current) {
                textareaRef.current.blur();
            }
        }, 200);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent new line on Enter
            handleSubmit(e); // Trigger form submission
        }
    };

    return (
        <AnimatePresence>
            <motion.form
                key="inputForm"
                className="w-full flex justify-center items-center z-40 fixed top-[300px]"
                onSubmit={handleSubmit}
                initial="default"
                animate={submitted ? 'submitted' : 'default'}
                variants={inputVariants}
            >
                <motion.textarea
                    ref={textareaRef}
                    placeholder="Search and let AI do the rest"
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown} // Listen for Enter key
                    animate={{ height: textareaHeight }} // Animate height dynamically
                    className={`w-[600px] rounded-3xl p-4 bg-gray-100 shadow-sm border border-gray-200
                        focus:outline-none hover:ring-2 hover:ring-emerald-300 focus:ring-2 focus:ring-emerald-300 focus:border-transparent focus:bg-white
                        ${submitted && 'bg-white'} resize-none overflow-hidden
                    `}
                />
            </motion.form>
        </AnimatePresence>
    );
}
