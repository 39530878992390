import React from 'react';

import LambdaSidebar from "../lambda_components/LambdaSidebar";
import TypedWords from '../lambda_components/TypedWords';
import LambdaInput from '../lambda_components/LambdaInput';
import ResultsLayout from '../lambda_components/ResultsLayout';

export const LambdaContext = React.createContext();

export default function Lambda() {

    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(true);
    const [intent, setIntent] = React.useState(null);
    const [answer, setAnswer] = React.useState(null);
    const [articles, setArticles] = React.useState([]);
    const [inputValue, setInputValue] = React.useState(''); // State for the input value
    const [followUpQuestions, setFollowUpQuestions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const values = { submitted, setSubmitted,
        intent, setIntent,
        answer, setAnswer,
        articles, setArticles,
        followUpQuestions, setFollowUpQuestions,
        inputValue, setInputValue,
        isLoading, setIsLoading
    };

    return (
        <LambdaContext.Provider value={values}>
        <div
            className="flex flex-col h-screen w-full justify-center items-center bg-lambda"
        >
            <LambdaSidebar 
                sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}
                submitted={submitted} setSubmitted={setSubmitted}
            />
            <div
                className="flex flex-col w-full h-full justify-center items-center gap-6"
            >
                <TypedWords submitted={submitted} setSubmitted={setSubmitted} />
                <LambdaInput submitted={submitted} setSubmitted={setSubmitted} />
                {submitted &&
                    (
                        <ResultsLayout />
                    )
                }
            </div>
        </div>
        </LambdaContext.Provider>
    )
}