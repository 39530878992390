import React from 'react';

import { LambdaContext } from '../pages/Lambda';
import { submitQuery } from './utility/submitQuery';


export default function FollowUpQuestions() {
    const { followUpQuestions } = React.useContext(LambdaContext);

    return (
        <FollowUpQuestionsList followUpQuestions={followUpQuestions} />
    )
}

function FollowUpQuestionsList({ followUpQuestions }) {
    return (
        <div
            className="flex flex-col w-full gap-2"
        >
            {followUpQuestions.map((question, index) => (
                <FollowUpQuestionComponent question={question} index={index} />
            ))}
        </div>
    )
}

function FollowUpQuestionComponent({ question, index }) {

    const { setIntent, setAnswer, setArticles, setFollowUpQuestions, setSubmitted, setInputValue, setIsLoading } = React.useContext(LambdaContext);

    const handleFollowUpQuestionClick = (question, setIntent, setAnswer, setArticles, setFollowUpQuestions, setSubmitted, setInputValue, setIsLoading) => async () => {
        await submitQuery(question, setIntent, setAnswer, setArticles, setFollowUpQuestions, setSubmitted, setInputValue, setIsLoading);
    }

    return (
        <button
            className="flex flex-row w-[300px] justify-start items-center gap-2 text-xs text-blue-700 border border-blue-700 p-2 rounded-xl
                        cursor-pointer hover:bg-blue-200 hover:text-white"
            key={index}
            onClick={handleFollowUpQuestionClick(question.question, setIntent, setAnswer, setArticles, setFollowUpQuestions, setSubmitted, setInputValue, setIsLoading)}
        >
            <p
                className="text-xs"
            >
                {question.question_summary}
            </p>
        </button>
    )
}