import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { IoOpenOutline } from "react-icons/io5";
import { MdContentCopy } from "react-icons/md";
import FocusIcon from "../assets/focus_logo_black.png";
import { MainUserContext } from "../App";
import React from 'react';
import EderSparkLogo from "../assets/logo-no-slogan-no-background.png";
import { FiThumbsUp } from "react-icons/fi";
import { FiThumbsDown } from "react-icons/fi";
import { createFocusChat } from "../components/SourcesDrawerList";
import { checkAuthStatus, fetchUserData } from '../services/authService';


export default function Paper() {
  const { paper_id } = useParams();
  const [paper, setPaper] = useState(null);
  const [loading, setLoading] = useState(true);
  const [exists, setExists] = useState(false);
  const [activeTab, setActiveTab] = useState('abstract');
  const [copySuccess, setCopySuccess] = useState(false);
  const { userId } = React.useContext(MainUserContext);
  const [canFocus, setCanFocus] = useState(false);
  const [focusLoading, setFocusLoading] = useState(false);
  const [checkFocusLoading, setCheckFocusLoading] = useState(false);
  const [voteStatus, setVoteStatus] = useState(null);
  const [voteLoading, setVoteLoading] = useState(false);
  const { setUser, setUserId, setLoggedIn, setEmail } = React.useContext(MainUserContext);

  useEffect(() => {
    const fetchPaper = async () => {
      try {
        console.log("FETCHING PAPER FROM URL", `${process.env.REACT_APP_API_URL}/api/paper/${paper_id}`);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/paper/${paper_id}`, {
          headers: {
            'Accept': 'application/json'
          }
        });
        console.log("Data received:", response.data);
        setPaper(response.data);
        setExists(true);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 404) {
          console.log("Paper not found");
          setExists(false);
          setLoading(false);
        } else {
          console.log("Error:", error);
          setLoading(false);
        }
      }
    };

    fetchPaper();
  }, [paper_id]);

  const handleCopy = async () => {
    try {
      const citation = `${paper?.title} (${paper?.year}) - ${paper?.authors.map(a => a.name).join(', ')}`;
      await navigator.clipboard.writeText(citation);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleFocusClick = async () => {
    console.log("CAN FOCUS", canFocus);
    if (!canFocus) return;
    
    setFocusLoading(true);
    try {
        createFocusChat({
            conversation_name: paper?.title,
            user_id: userId,
            paper_id: paper_id,
            title: paper?.title,
            authors: paper?.authors,
            abstract: paper?.abstract,
            url: paper?.openAccessPdf?.url
          }, true)
    } catch (error) {
      console.error('Error starting Focus session:', error);
    }
    setFocusLoading(false);
  };

  useEffect(() => {
    const checkFocus = async () => {
      if (!paper?.openAccessPdf?.url || !userId) {
        console.log("No PDF available for Focus");
        setCanFocus(false);
        return;
      }
      console.log("PDF available for Focus");
      console.log("FOCUS URL", paper?.openAccessPdf?.url);
      
      try {
        setCheckFocusLoading(true);
        console.log("Checking focus availability");
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/focus/pdf?url=${paper.openAccessPdf.url}`);
        console.log("PDF fetched successfully");
        if (response.data) {
          console.log("PDF is valid for Focus");
          setCanFocus(true);
        } else {
          console.log("PDF is not valid for Focus");
          setCanFocus(false);
        }
        setCheckFocusLoading(false);
      } catch (error) {
        console.error('Error checking focus availability:', error);
        setCanFocus(false);
        setCheckFocusLoading(false);
      }
    };

    if (paper) {
      checkFocus();
    }
  }, [paper]);

  const handleVote = async (voteType) => {
    if (voteLoading) return;
    setVoteLoading(true);
    
    try {
      const endpoint = voteType === 'up' ? 'thumbup' : 'thumbdown';
      await axios.post(`${process.env.REACT_APP_API_URL}/api/paper/${endpoint}/${paper_id}`);
      setVoteStatus(voteType);
    } catch (error) {
      console.error('Error voting:', error);
    } finally {
      setVoteLoading(false);
    }
  };

  React.useEffect(() => {
    const validateAuth = async () => {
      try {
        const user = await checkAuthStatus();
        
        if (user) {
          const userData = await fetchUserData(user.mail);
          setUser(userData);
          setUserId(userData.user_id);
          setLoggedIn(true);
          setEmail(userData.mail);
        } else {
          handleUnauthorized();
        }
      } catch (error) {
        handleUnauthorized();
      }
    };

    const handleUnauthorized = () => {
      setLoggedIn(false);
      window.location.replace('https://ederspark.com/?page=platform');
    };

    validateAuth();
  }, [setUser, setUserId, setLoggedIn, setEmail]);

  if (loading) {
    return (
      <div className='flex flex-col items-center justify-center h-screen w-screen font-sans text-gray-800'>
        <div className='flex flex-col items-center space-y-6'>
          <div className='animate-pulse'>
            <span className='text-6xl'>📑</span>
          </div>
          <h1 className='text-2xl font-bold text-gray-700'>Loading Paper...</h1>
          <div className='flex space-x-3'>
            <div className='w-3 h-3 bg-emerald-500 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
            <div className='w-3 h-3 bg-emerald-500 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
            <div className='w-3 h-3 bg-emerald-500 rounded-full animate-bounce'></div>
          </div>
        </div>
      </div>
    );
  }

  if (!exists && !loading) {
    return (
      <div className='flex flex-col items-center justify-center h-screen w-screen font-sans text-gray-800'>
        <div className='flex flex-col items-center space-y-4'>
          <span className='text-6xl'>📚</span>
          <h1 className='text-3xl font-bold text-gray-700'>Paper Not Found</h1>
          <p className='text-gray-500 text-center max-w-md'>
            We couldn't find the paper you're looking for. It might have been removed or the ID might be incorrect.
          </p>
          <a 
            href="/sparkai"
            className='mt-4 px-4 py-2 border border-emerald-500 text-emerald-500 rounded-lg hover:bg-emerald-200 hover:text-white transition-colors'
          >
            Return to SparkAI
          </a>
        </div>
      </div>
    );
  }

  return (
    <div
      className='flex flex-col items-center justify-center md:justify-start min-h-screen w-full font-sans text-gray-800 pt-[110px] md:pt-10'
    >
      <div className='flex flex-col items-center justify-start absolute top-0 left-0 right-0 md:top-10 md:left-10 md:right-auto space-y-4 p-4 md:p-0 bg-white'>
        <img src={EderSparkLogo} alt="EderSpark Logo" className='w-[120px] md:w-[175px]' />
        <button 
          className='border border-emerald-500 text-emerald-500 px-2 py-1 rounded-lg hover:bg-emerald-200 hover:text-white transition-colors text-sm md:text-base'
          onClick={() => window.location.href = '/sparkai'}
        >
          Return to SparkAI
        </button>
      </div>

      <div className='flex flex-col items-start justify-start w-10/12 md:w-7/12 space-y-3'>
        <span className='text-xl md:text-2xl font-bold'>{paper?.title}</span>
        <span className='text-sm md:text-base text-gray-500'>{paper?.authors.map((author) => author.name).join(', ')}</span>

        <div className='flex flex-row items-center justify-start space-x-2 flex-wrap gap-y-2'>
          <span className='text-xs md:text-sm font-bold text-gray-700'>{paper?.citationCount} citations</span>
          <span className='text-xs md:text-sm font-bold text-gray-700'>{paper?.referenceCount} references</span>
          {paper?.openAccessPdf?.url && (
            <span className='px-2 py-1 text-xs font-medium text-emerald-700 bg-emerald-100 rounded-full'>
              Open Access
            </span>
          )}
        </div>

        <div className='flex flex-row items-center space-x-4 flex-wrap gap-y-2'>
          {paper?.openAccessPdf?.url ? (
            <a 
              href={paper.openAccessPdf.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="flex items-center text-sm text-gray-600 hover:text-gray-800 transition-colors duration-200"
            >
              <IoOpenOutline className="mr-1" />
              <span>Download paper</span>
            </a>
          ) : (
            <span className="flex items-center text-sm text-gray-400 cursor-not-allowed group relative">
              <IoOpenOutline className="mr-1" />
              <span>Download paper</span>
              <span className="absolute -top-8 left-0 bg-gray-800 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                No PDF available
              </span>
            </span>
          )}
          
          <a 
            href={`https://www.semanticscholar.org/paper/${paper?.paperId}`} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="flex items-center text-sm text-gray-600 hover:text-gray-800 transition-colors duration-200"
          >
            <IoOpenOutline className="mr-1" />
            <span>Semantic Scholar</span>
          </a>

          <button 
            onClick={handleCopy}
            className={`flex items-center text-sm transition-colors duration-200 ${
              copySuccess ? 'text-emerald-600' : 'text-gray-600 hover:text-gray-800'
            }`}
          >
            <MdContentCopy className="mr-1" />
            <span>{copySuccess ? 'Copied!' : 'Copy Citation (BibTeX)'}</span>
          </button>

          <button 
            onClick={handleFocusClick}
            className={`flex items-center text-sm transition-colors duration-200 space-x-1 ${
              canFocus 
                ? 'text-gray-600 hover:text-gray-800' 
                : 'text-gray-400 cursor-not-allowed'
            }`}
          >
            <img src={FocusIcon} alt="Focus Logo" className="w-4 h-4" />
            <span>{checkFocusLoading ? 'Checking if it can be focused... (Might take up to a minute)' : 'Focus'}</span>
            {!canFocus && (
              <span className="absolute -top-8 left-0 bg-gray-800 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                PDF not available for Focus
              </span>
            )}
          </button>
        </div>
        {paper?.publicationTypes && paper.publicationTypes.length > 0 && (
          <div className="flex flex-wrap gap-2 my-4">
            {paper.publicationTypes.map((type, index) => (
              <span
                key={index}
                className="px-2 py-1 text-xs font-medium text-emerald-700 bg-emerald-100 rounded-full"
              >
                {type}
              </span>
            ))}
          </div>
        )}
        {paper?.fieldsOfStudy && paper.fieldsOfStudy.length > 0 && (
          <div className="flex flex-wrap gap-2 my-4">
            {paper.fieldsOfStudy.map((field, index) => (
              <span
                key={index}
                className="px-2 py-1 text-xs font-medium text-blue-700 bg-blue-100 rounded-full"
              >
                {field}
              </span>
            ))}
          </div>
        )}
        <div className="flex items-center space-x-4 my-2">
          <button
            onClick={() => handleVote('up')}
            disabled={voteLoading}
            className={`flex items-center space-x-1 p-2 rounded-lg transition-colors ${
              voteStatus === 'up'
                ? 'text-emerald-600 bg-emerald-50'
                : 'text-gray-600 hover:text-emerald-600 hover:bg-emerald-50'
            }`}
          >
            <FiThumbsUp className={`${voteStatus === 'up' ? 'fill-emerald-600' : ''}`} />
          </button>
          <button
            onClick={() => handleVote('down')}
            disabled={voteLoading}
            className={`flex items-center space-x-1 p-2 rounded-lg transition-colors ${
              voteStatus === 'down'
                ? 'text-red-600 bg-red-50'
                : 'text-gray-600 hover:text-red-600 hover:bg-red-50'
            }`}
          >
            <FiThumbsDown className={`${voteStatus === 'down' ? 'fill-red-600' : ''}`} />
          </button>
        </div>
        <div className='w-full'>
          <div className='flex border-b border-gray-200'>
            <button
              className={`py-2 px-3 md:px-4 font-medium text-xs md:text-sm ${
                activeTab === 'abstract'
                  ? 'border-b-2 border-emerald-500 text-emerald-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveTab('abstract')}
            >
              Abstract
            </button>
            <button
              className={`py-2 px-3 md:px-4 font-medium text-xs md:text-sm ${
                activeTab === 'tldr'
                  ? 'border-b-2 border-emerald-500 text-emerald-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveTab('tldr')}
            >
              Intelligent Summary
            </button>
          </div>
          
          <div className='pt-4 text-justify'>
            <p className='text-sm md:text-base font-light'>
              {activeTab === 'abstract' ? paper?.abstract : paper?.tldr?.text || "No intelligent summary available. We're working on it!"}
            </p>
          </div>

          {paper?.references && paper.references.length > 0 && (
            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-4">References in this paper</h3>
              <div className="space-y-3">
                {paper.references.map((ref, index) => (
                  <div>
                    <div key={ref.paperId} className="flex items-center justify-between">
                        <div className="flex items-center flex-1">
                        <span className="min-w-6 min-h-6 flex items-center justify-center rounded-full border border-gray-400 text-xs text-gray-600 mr-3">
                            {index + 1}
                        </span>
                        <span className="text-sm text-gray-700">{ref.title}</span>
                        </div>
                        <button
                        onClick={() => window.location.href = `/paper/${ref.paperId}`}
                        className="ml-4 px-3 py-1 text-xs border border-emerald-500 text-emerald-500 rounded-lg hover:bg-emerald-200 hover:text-white transition-colors"
                        >
                        View
                        </button>
                    </div>
                    <hr className="my-4" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
