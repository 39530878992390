import React from 'react'

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'

import { SparkleContext } from '../pages/Sparkle'

import { motion } from 'framer-motion';


const testWord = [
    {
        letter: 'W',
        status: 'correct-letter'
    },
    {
        letter: 'O',
        status: 'wrong-letter'
    },
    {
        letter: 'R',
        status: 'bad-placed-letter'
    },
    {
        letter: 'D',
        status: 'wrong-letter'
    }
]


function SparkleLetter({ letter, status, size = 'normal' }) {
    return (
        <motion.span
            key={status} // Reinicia la animación cuando el `status` cambia
            className={`font-bold p-2 text-center rounded-lg shadow-md flex justify-center items-center
                        ${size === 'small' ? 'w-8 h-8 sm:w-10 sm:h-10 md:w-14 md:h-14 text-2xl' : 'w-8 h-8 sm:w-10 sm:h-10 md:w-14 md:h-14 text-2xl md:text-3xl'}
                        ${status}`
                    }
            initial={{ scale: 0 }}
            animate={{ scale: 1.0 }}
            transition={{
                type: 'spring',
                stiffness: 300,
                damping: 10,
                duration: 0.5,
            }}
        >
            <p>
                {status === 'not-tried-letter' ? '?' : letter}
            </p>
        </motion.span>
    );
}

export function SparkleWordHistory({ words, hidden = false }) {

    return (
        <div
            className='flex flex-col w-full h-full items-center'
        >
            <div
                className="flex flex-col gap-3 overflow-y-auto h-full w-[85%] justify-start items-start"
            >
                {words.map((word) => (
                    <div
                        className="flex flex-row justify-center items-start gap-1 md:gap-3 w-full"
                    >
                        {word.map((letter) => (
                            <SparkleLetter
                                letter={letter.letter}
                                status={letter.status}
                                size="small"
                            />
                        ))}
                    </div>
                ))}
            </div>
            <SparkleWordToggleHistoryButton direction="up" hidden={hidden} tag="Chat" />
        </div>
    )
}

function SparkleWordToggleHistoryButton({ direction = 'down', hidden = false, tag = 'History' }) {

    const { historyOpen, setHistoryOpen } = React.useContext(SparkleContext)

    return (
        <div
            className="flex flex-row justify-center items-center"
        >
            <button
                className={`flex-col justify-center items-center p-1 rounded-lg bg-emerald-200 text-emerald-600 
                            shadow-sm w-20
                            hover:bg-emerald-300 hover:text-emerald-700
                            hover:scale-110 transition-transform duration-200 ease-in-out
                            ${direction === 'down' ? 'rounded-b-full' : 'rounded-t-full relative bottom-1'}
                            ${hidden ? 'hidden' : 'flex'}
                            `}
                onClick={() => setHistoryOpen(!historyOpen)}
            >
                <p
                    className="text-[0.60rem] font-semibold"
                >
                    {tag}
                </p>
                {direction === 'down' ? (
                    <ArrowDownIcon
                    className="h-3 w-3"
                    />
                ) : (
                    <ArrowUpIcon
                    className="h-3 w-3"
                    />
                )}
            </button>
        </div>
    )
}

export default function SparkleWordModule({hidden = false}) {

    const [wordOfTheDay, setWordOfTheDay] = React.useState('Gravedad')
    const { guessing, setGuessing } = React.useContext(SparkleContext)

    return (
        <div
            className="flex flex-col gap-1"
        >
            <div
                className="flex flex-row gap-1 md:gap-3 text-3xl md:text-4xl text-center text-emerald-800"
            >
                {guessing.map((letter) => (
                    <SparkleLetter
                        letter={letter.letter}
                        status={letter.status}
                    />
                ))}
            </div>
            <SparkleWordToggleHistoryButton direction="down" hidden={hidden} tag="History" />
        </div>
    )
}