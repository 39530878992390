import { HiBars3 } from "react-icons/hi2";
import { IoSearch } from "react-icons/io5";
import { motion } from "framer-motion";


export default function LambdaSidebar({ sidebarOpen, setSidebarOpen, submitted, setSubmitted }) {

    return (
        <div
            className={`flex flex-col h-full bg-lambda-light fixed top-0 left-0 border-r-2 shadow-lg
                    ${sidebarOpen ? 'w-64' : 'w-20'} transition-all duration-300 z-50
                `}
        >
            <div
                className={`flex justify-start relative
                            items-center
                            h-[10%] w-full
                        `}
            >
                <span
                    className={`flex hover:bg-gray-100 rounded-full p-2
                                absolute left-4 cursor-pointer
                            `}
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                    <HiBars3
                        className={`text-3xl text-black
                                    w-7 h-7
                                `}
                    />
                </span>
            </div>
            <div
                className={`flex flex-col justify-center items-center
                            h-[20%] w-full
                        `}
            >
                <div
                    className={`flex justify-center items-center w-11/12 py-2
                            ${sidebarOpen ? 'hover:bg-gray-100': ''}
                            cursor-pointer    
                            rounded-full
                        `}
                >
                    <span
                        className={`flex hover:bg-gray-100 rounded-full p-2
                                    absolute left-[18px]
                                `}
                        onClick={() => setSubmitted(false)}
                    >
                        <IoSearch
                            className={`text-black cursor-pointer
                                        w-5 h-5
                                    `}
                        />
                    </span>
                    <motion.div
                        className={`flex flex-col justify-center items-center
                                    w-full
                                `}
                        onClick={() => setSubmitted(false)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3, delay: 0.25 }}
                        key={sidebarOpen}
                    >
                        <p
                            className={`text-gray-700 text-md
                                        cursor-pointer
                                    `}
                            hidden={!sidebarOpen}
                        >
                            Start a new search
                        </p>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}