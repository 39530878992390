import { LuAward } from "react-icons/lu"
import { IoSend } from "react-icons/io5"
import { CircularProgress } from "@mui/material"
import { motion } from "framer-motion"
import { SparkAICall } from "../components/helpers"
import React from "react"
import axios from "axios"
import SpinnerLoader from "../common_components/SpinnerLoader"
import { createFocusChat } from "../components/SourcesDrawerList"

import { MainUserContext } from "../App"

function Article({ article, setCreatingFocusChat }) {

    const { userId } = React.useContext(MainUserContext);

    return (
        <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="flex flex-col justify-start items-start bg-purple-50 border-2 border-purple-800 rounded-lg p-4 w-[800px] max-w-[90vw] sm:max-w-[500px] md:max-w-[800px] min-h-[80px]
                hover:bg-purple-100 hover:border-purple-900 transition-all duration-300 ease-in-out cursor-pointer scale-100 hover:scale-105
                group relative hover:z-10"
        >
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center w-full gap-2 sm:gap-0">
                <div className="flex flex-col sm:flex-row justify-between w-full gap-2">
                    <div className="flex flex-col sm:flex-row gap-2 items-start sm:items-center">
                        <span className="text-sm sm:text-md font-semibold break-words">{article.title}</span>
                        <span className={`text-xs px-2 py-1 rounded-full whitespace-nowrap ${article.isOpenAccess ? 'bg-green-100 text-green-800 border-2 border-green-300' : 'bg-red-100 text-red-800 border-2 border-red-300'}`}>
                            {article.isOpenAccess ? 'Open Access' : 'Not Open Access'}
                        </span>
                    </div>
                    <span className="text-xs text-gray-500">{article.year}</span>
                </div>
            </div>
            <span className="text-xs text-gray-500 break-words">
                {article.authors}
            </span>
            {article.isOpenAccess ? (
                <div className="absolute -bottom-8 left-0 bg-purple-800 text-white border-2 border-purple-900 shadow-lg rounded-lg px-3 py-1.5 text-sm 
                    opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out transform translate-y-1 group-hover:translate-y-0
                    flex items-center gap-2 hover:bg-purple-100 hover:border-purple-300 hover:text-purple-800 font-semibold"
                    onClick={async () =>{
                        setCreatingFocusChat(true);
                        const response = await axios.post(process.env.REACT_APP_API_URL + '/api/qdrant/add_paper/', {
                            url: article.openAccessPdf.url ? article.openAccessPdf.url : article.url,
                            paper_id: article.paperId,
                            title: article.title,
                            authors: article.authors,
                            abstract: article.abstract
                        })
                        const cfc_response = await createFocusChat({
                            conversation_name: article.title,
                            user_id: userId,
                            paper_id: article.paperId,
                            title: article.title,
                            authors: article.authors,
                            abstract: article.abstract,
                            url: article.openAccessPdf.url ? article.openAccessPdf.url : article.url,
                        }, false)
                        console.log('Response from focus chat create:', response);
                        setCreatingFocusChat(false);
                    }
                }
                >
                    <LuAward className="w-4 h-4" />
                    Open in Focus
                </div>
            ) : (
                <div className="absolute -bottom-8 left-0 bg-gray-400 text-white border-2 border-gray-500 shadow-lg rounded-lg px-3 py-1.5 text-sm 
                    opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out transform translate-y-1 group-hover:translate-y-0
                    flex items-center gap-2 cursor-not-allowed"
                    title="This paper is not openly accessible to everyone"
                >
                    <LuAward className="w-4 h-4" />
                    Not Available in Focus
                </div>
            )}
        </motion.div>
    );
}

function ArticleSearchResults({ articles, submitted, isLoading, loadingMessage, setCreatingFocusChat }) {
    if (isLoading) {
        return (
            <div className="flex flex-col gap-4 justify-center items-center mt-10">
                <SpinnerLoader />
                <span className="text-sm text-gray-500">{loadingMessage}</span>
            </div>
        )
    }
    if (articles.length === 0 && submitted) {
        return (
            <div className="flex flex-col justify-start items-start gap-2 font-semibold text-purple-800 mt-10">
                <span className="text-sm text-gray-500">No articles found, try searching for a different paper</span>
            </div>
        )
    }

    return (
        <div className="flex flex-col justify-start items-start gap-2">
            {articles.map(article => (
                <Article key={article.paperId} article={article} setCreatingFocusChat={setCreatingFocusChat} />
            ))}
        </div>
    );
}

export default function NoPaper() {

    const [chatInput, setChatInput] = React.useState('');
    const [articles, setArticles] = React.useState([]);
    const [searchType, setSearchType] = React.useState('name');
    const [submitted, setSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingMessage, setLoadingMessage] = React.useState('Searching for articles...');
    const [creatingFocusChat, setCreatingFocusChat] = React.useState(false);

    const processArticles = async (articles, setLoadingMessage) => {
        setLoadingMessage('Checking if articles are open access... (This may take a few seconds)');
        for (const article of articles) {
            console.log("Fetching Article");
            article.authors = article.authors ? article.authors.map(author => author.name).join(', ') : '';
            if (article.openAccessPdf !== null) {
                const pdfProxyUrl = `${process.env.REACT_APP_API_URL}/api/focus/pdf?url=${encodeURIComponent(article.openAccessPdf.url)}`;
                console.log("PDF Proxy URL: ", pdfProxyUrl);
                // Check if the PDF is actually accessible
                try {
                    console.log("Fetching PDF URL: ", article.openAccessPdf.url);
                    const response = await axios.get(pdfProxyUrl);
                    console.log("Response: ", response);
                    article.isOpenAccess = response.status === 200;
                } catch (error) {
                    console.log("Error: ", error);
                    article.isOpenAccess = false;
                }
            } else {
                console.log("No PDF URL");
                article.isOpenAccess = false;
            }
        }
        return articles;
    }

    const handleSubmitName = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`https://api.semanticscholar.org/graph/v1/paper/search?query=${encodeURIComponent(chatInput)}&offset=0&limit=3&fields=title,url,abstract,authors,year,openAccessPdf`);
            const jsonResponse = await response.json();
            const processedArticles = await processArticles(jsonResponse.data, setLoadingMessage);
            setArticles(processedArticles);
            console.log(processedArticles);
        } catch (error) {
            console.error('Error fetching articles:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmitDoi = async (e) => {
        e.preventDefault();
        setSubmitted(true);

        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`https://api.semanticscholar.org/graph/v1/paper/DOI:${encodeURIComponent(chatInput)}?fields=title,url,abstract,authors,year,openAccessPdf`);
            const article = await response.json();
            const processedArticles = await processArticles([article], setLoadingMessage);
            setArticles(processedArticles);
            console.log(processedArticles);
        } catch (error) {
            console.error('Error fetching article:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmitArxiv = async (e) => {
        e.preventDefault();
        setSubmitted(true);

        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`https://api.semanticscholar.org/graph/v1/paper/ARXIV:${encodeURIComponent(chatInput)}?fields=title,url,abstract,authors,year,openAccessPdf`);
            const article = await response.json();
            const processedArticles = await processArticles([article], setLoadingMessage);
            setArticles(processedArticles);
            console.log(processedArticles);
        } catch (error) {
            console.error('Error fetching article:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (e) => {
        setLoadingMessage('Searching for articles...');
        setLoading(true);
        if (searchType === 'name') {
            handleSubmitName(e);
        } else if (searchType === 'doi') {
            handleSubmitDoi(e);
        } else if (searchType === 'arxiv') {
            handleSubmitArxiv(e);
        }
        setLoading(false);
    }

    const [textareaHeight, setTextareaHeight] = React.useState(50); // Default height of the textarea
    const textareaRef = React.useRef(null);

    React.useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height to calculate correct scrollHeight
            const newHeight = textareaRef.current.scrollHeight;
            // Cap the height at 150px
            if (newHeight > 125) {
                textareaRef.current.style.height = "125px"; // Maximum height
                textareaRef.current.style.overflowY = "auto"; // Add scrollbar when exceeding max height
                textareaRef.current.style.borderRadius = "25"; // Round the corners
                setTextareaHeight(150); // Update height state for animation
            } else {
                textareaRef.current.style.height = `${newHeight}px`; // Allow auto-resizing below 150px
                textareaRef.current.style.overflowY = "hidden"; // Hide scrollbar when below max height
                textareaRef.current.style.borderRadius = "25"; // Round the corners
                setTextareaHeight(newHeight); // Update height state for animation
            }
        }
    }, [chatInput]);    

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent new line on Enter
            if (searchType === 'name') {
                handleSubmitName(e); // Trigger form submission
            } else if (searchType === 'doi') {
                handleSubmitDoi(e); // Trigger form submission
            } else if (searchType === 'arxiv') {
                handleSubmitArxiv(e); // Trigger form submission
            }
        }
    };


    if (creatingFocusChat) {
        return (
            <div className="flex flex-col justify-start items-center h-screen gap-3 pt-36">
                <SpinnerLoader />
                <span className="text-sm text-gray-500">Loading your chat...</span>
            </div>
        )
    }

    return (
        <div
            className="flex flex-col justify-start items-center h-screen gap-3 pt-36"  
        >
            <div
                className="text-2xl md:text-4xl font-semibold text-purple-800"
            >
                Search for a paper
            </div>
            <div className="flex gap-4">
                <button
                    className={`text-xs md:text-sm font-semibold w-16 md:w-24 h-8 md:h-8 rounded-lg ${searchType === 'name' ? 'bg-purple-800 text-white' : 'bg-gray-200 text-gray-700'}`}
                    onClick={() => setSearchType('name')}
                >
                    Name
                </button>
                <button 
                    className={`text-xs md:text-sm font-semibold w-16 md:w-24 h-8 md:h-8 rounded-lg ${searchType === 'doi' ? 'bg-purple-800 text-white' : 'bg-gray-200 text-gray-700'}`}
                    onClick={() => setSearchType('doi')}
                >
                    DOI
                </button>
                <button 
                    className={`text-xs md:text-sm font-semibold w-16 md:w-24 h-8 md:h-8 rounded-lg ${searchType === 'arxiv' ? 'bg-purple-800 text-white' : 'bg-gray-200 text-gray-700'}`}
                    onClick={() => setSearchType('arxiv')}
                >
                    arXiv
                </button>
            </div>
            <form
                className="w-full flex justify-center pl-10"
                onSubmit={handleSubmit}
            >
                <motion.textarea required
                    ref={textareaRef}
                    className="input-chat w-[500px] md:w-[600px] placeholder-gray-500"
                    value={chatInput}
                    onChange={(e) => setChatInput(e.target.value)}
                    startDecorator={<LuAward/>}
                    variant="outline" placeholder={`Search by ${searchType === 'name' ? 'name' : searchType === 'doi' ? 'DOI' : 'arXiv ID'}`}
                    onKeyDown={handleKeyDown} // Listen for Enter key
                    animate={{ height: textareaHeight }} // Animate height dynamically
                    disabled={loading}
                />
                <div
                    className="flex justify-center items-center relative right-14"
                >
                    <button
                        className="input-button"
                        type='submit'
                        disabled={loading}>
                            {loading ? 
                            <CircularProgress 
                            variant='plain'
                            thickness={1}
                            size="sm"
                            /> : 
                            <IoSend
                                className="text-gray-600 w-6 h-6"
                            />
                        }
                    </button>
                </div>
            </form>
            <ArticleSearchResults
                articles={articles}
                submitted={submitted}
                isLoading={loading} setLoading={setLoading}
                loadingMessage={loadingMessage} setLoadingMessage={setLoadingMessage} 
                setCreatingFocusChat={setCreatingFocusChat}
            />
        </div>
    )
}