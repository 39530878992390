import { Stack, Typography } from '@mui/joy';
import '../index.css';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeRaw from 'rehype-raw'

import { VscReferences } from "react-icons/vsc";
import SparkAILogo from '../assets/sparkai_new_only_logo.png';
import ResponseRenderer from './ResponseRenderer';

import { FaEye } from "react-icons/fa";


function SharedChatMessage({ role_id, content, messageId }) {
    const displayRole = role_id === 1 ? '' : 'SparkAI';
    const roleClass = role_id === 1 ? 'message-user' : 'message-bot';
    const containerClass = role_id === 1 ? 'message-container-user' : 'message-container-bot';
    const [citations, setCitations] = React.useState(null);

    // Fetch citations from the api
    React.useEffect(() => {
        if (messageId) {
            axios.get(`${process.env.REACT_APP_API_URL}/api/messages/citations/${messageId}`)
                .then(response => {
                    setCitations(response.data);
                })
                .catch(error => {
                    console.error('Error fetching citations:', error);
                });
        }
    }, [messageId]);

    return (
        <div className={`${containerClass} relative`}>
            <div
                className={`flex absolute top-6 
                        ${role_id === 2 ? `left-[-30px]` : ` 
                        right-[-60px]`}`}
            >
                {displayRole === 'SparkAI' ? (
                    <img
                        className="w-[22px] h-[24px] hidden md:block"
                        src={SparkAILogo} alt="SparkAI_logo"
                    />
                    ) : (
                        <p 
                            className="text-sm md:text-base text-gray-700 text-justify font-semibold hidden md:block"
                        >
                            {displayRole}
                        </p>
                    )
                }
            </div>
            <div className={`${roleClass} w-full relative flex flex-col gap-2 relative`}>
                {role_id === 2 ? (
                    <ResponseRenderer 
                        response={content} 
                        citations={citations || {}}
                        showFocus={false}
                    />
                ) : (
                    <Markdown
                        remarkPlugins={[[remarkGfm], [remarkMath]]} 
                        rehypePlugins={[[rehypeKatex], [rehypeRaw]]}
                        className="text-lg md:text-base text-gray-800 md:leading-10"
                    >
                        {content}
                    </Markdown>
                )}
            </div>
        </div>
    );
}

function SharedMessagesList({ messages, chatName, views }) {
    return (
        <div className='flex flex-col w-full h-full justify-start items-center overflow-y-auto mt-[50px] md:mt-0'>
            <div className="flex flex-col gap-5 w-full justify-start items-center mb-[90px]">
                <div className="h-full w-full overflow-x-hidden justify-center flex pt-28">
                    <div className="flex flex-col gap-10 w-11/12 sm:w-[58%]">
                        <div className="text-center mb-4">
                            <h1 className="text-xl font-semibold">
                                {chatName}
                            </h1>
                            <p className="text-sm text-gray-600">
                                Shared conversation
                            </p>
                            <p className="text-sm text-gray-600 flex items-center justify-center">
                                <FaEye className="mr-1" /> {views} views
                            </p>
                        </div>
                        {messages.map((chat, index) => (
                            <SharedChatMessage 
                                key={index} 
                                role_id={chat.role_id} 
                                content={chat.content} 
                                messageId={chat.message_id}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function SharedChatView() {
    const { shareId } = useParams();
    const [messages, setMessages] = React.useState([]);
    const [chatName, setChatName] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [views, setViews] = React.useState(0);
    const hasInitialized = React.useRef(false);

    React.useEffect(() => {
        const viewKey = `shared-chat-view-${shareId}`;
        const lastViewTime = parseInt(localStorage.getItem(viewKey));
        console.log("Last view time:", lastViewTime);
        const currentTime = new Date().getTime();
        const COOLDOWN_PERIOD = 12 * 60 * 60 * 1000; // 24 hours in milliseconds

        const initializeViews = async () => {
            try {
                // First get current view count
                const viewResponse = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/shared-chats/${shareId}/get-views`
                );
                setViews(viewResponse.data.views);

                // Check if enough time has passed since last view
                const canAddView = !lastViewTime || isNaN(lastViewTime) || (currentTime - lastViewTime) > COOLDOWN_PERIOD;
                console.log("Can add view:", canAddView, "Time difference:", currentTime - lastViewTime);

                if (canAddView) {
                    // Store current timestamp
                    localStorage.setItem(viewKey, currentTime.toString());
                    console.log("View added");
                    const addResponse = await axios.post(
                        `${process.env.REACT_APP_API_URL}/api/shared-chats/${shareId}/add-view`,
                        {
                            timestamp: currentTime,
                            shareId: shareId
                        }
                    );
                    
                    if (addResponse.data.success) {
                        setViews(prev => prev + 1);
                    }
                }
            } catch (error) {
                console.error('Error handling views:', error);
            }
        };

        if (!hasInitialized.current) {
            hasInitialized.current = true;
            initializeViews();
        }
    }, [shareId]);

    React.useEffect(() => {
        const fetchSharedChat = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/shared-chats/${shareId}`);
                if (response.data.status === 'success') {
                    console.log("Response data:", response.data);
                    setMessages(response.data.share.messages);
                    setChatName(response.data.share.conversation_name);
                } else {
                    setError('Failed to load shared chat');
                }
            } catch (err) {
                setError('This shared chat may have expired or does not exist');
                console.error('Error fetching shared chat:', err);
            } finally {
                setLoading(false);
            }
        };
        console.log("Fetching shared chat for:", shareId);
        if (shareId) {
            fetchSharedChat();
        }
    }, [shareId]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="flex flex-col items-center gap-2">
                    <img 
                        src={SparkAILogo} 
                        alt="SparkAI Logo" 
                        className="w-8 h-8 animate-pulse"
                    />
                    <p>Loading shared chat...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="flex flex-col items-center gap-2">
                    <p className="text-red-600">{error}</p>
                </div>
            </div>
        );
    }

    return <SharedMessagesList messages={messages} chatName={chatName} views={views} />;
} 