import SparkleChatMessage from "./SparkleChatMessage";
import React, { useEffect, useRef } from "react";
import { SparkleContext } from "../pages/Sparkle";

export default function SparkleChat() {
  const { messages } = React.useContext(SparkleContext);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div
      ref={chatContainerRef}
      className="flex flex-col w-11/12 lg:w-7/12 h-full p-2 overflow-y-auto" 
    >
      {messages.map((message, index) => (
        <SparkleChatMessage
          key={index} // Add a unique key for each message
          user={message.user}
          text={message.text}
          time={message.time}
        />
      ))}
    </div>
  );
}