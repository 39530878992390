import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';

export default function TypedWords({submitted, setSubmitted}) {

    const inputVariants = {
        default: {
            opacity: 1,
            transition: { duration: 0.3, type: "tween", ease: "easeInOut" },
        },
        submitted: {
            opacity: 0,
            transition: { duration: 0.3, type: "tween", ease: "easeInOut" },
        },
    };

    return (
        <motion.div
            className="flex flex-row justify-center items-center fixed bottom-[400px]"
            initial="default"
            animate={submitted ? 'submitted' : 'default'}
            variants={inputVariants}
        >
            <TypeAnimation
            sequence={[
                // Same substring at the start will only be typed out once, initially
                'Make incredible searchs',
                1000,
                'Make valuable discoveries',
                1000,
                'Make unrivaled researchs',
                1000
            ]}
            wrapper="span"
            speed={25}
            deletionSpeed={15}
            style={{ fontSize: '2.5em'}}
            className='text-lambda-dark inline-block'
            repeat={Infinity}
            />
        </motion.div>
    );
}
