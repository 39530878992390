import { Badge, Tooltip } from "@mui/material";
import { BiArrowToLeft } from "react-icons/bi";
import { BiArrowToRight } from "react-icons/bi";
import '../index.css';
import FocusLogo from '../assets/focus_only_logo_upscaled.png';
import Typography from "@mui/joy/Typography";
import { useMediaQuery } from '@mui/material';
import { Stack } from "@mui/joy";
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React from "react";

import { Select } from '@mui/base/Select';
import { Option } from '@mui/base/Option';
import { UserContext } from "../pages/Focus";


function BoltIcon () {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
            className="h-4 w-4 text-gray-500">
        <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
        </svg>
    )
}

export default function TopHeader({isOpen, toggleDrawer, currentChatName, marginLeft}) {

    const isSmallScreen = useMediaQuery('(max-width: 1000px)');
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { model, setModel } = React.useContext(UserContext);
    const [ modelMenuOpen, setModelMenuOpen ] = React.useState(false);

    return (
        <div 
            className="flex flex-row h-14 justify-start items-center gap-8 fixed w-full shadow-[inset_0_50px_50px_-10px_rgba(255,255,255,0.9)] z-[9999] translation-all duration-300 bg-white pl-10"
            style={{marginLeft: marginLeft}}
        >
            <div
                className="w-auto hidden md:block max-w-[500px] flex-row items-center gap-2"
            >
                <Tooltip title="Chat selected" placement="bottom">
                <text
                    className="text-gray-800 text-md font-semibold whitespace-nowrap overflow-hidden text-ellipsis block"
                    >
                        {currentChatName}
                </text>
                </Tooltip>
            </div>
            <div
                className="w-auto"    
            >
                    <div
                        className="flex flex-col justify-center items-center gap-1 relative"
                    >
                        {/* Button to toggle the menu */}
                        <button
                            className="flex flex-row gap-1 justify-center items-center text-gray-500 text-md text-center whitespace-nowrap rounded-lg p-2 bg-gray-200 hover:bg-gray-100"
                            onClick={() => setModelMenuOpen(!modelMenuOpen)}
                            onBlur={() => setModelMenuOpen(false)}
                        >
                            <text className="text-md">{ model === 'default' ? 'Focus' : 'Focus' }</text>
                            { model==='smart' && <BoltIcon />}
                            <ChevronDownIcon className="w-5 h-5" />
                        </button>

                        {/* Dropdown Menu */}
                        {modelMenuOpen && (
                            <div
                                className="absolute top-full mt-2 left-0 w-80 drop-shadow-md rounded-lg p-3 z-10 border-2 border-gray-200 bg-white flex flex-col gap-1"
                                onMouseLeave={() => setModelMenuOpen(false)}
                            >
                                <span
                                    className="text-gray-500 text-sm font-semibold px-2"
                                >
                                    Model
                                </span>
                                <button
                                    disabled={messageIsLoading}
                                    className="text-left hover:bg-gray-100 rounded-lg p-2"
                                    onClick={() => {setModel('default'); setModelMenuOpen(false)}}
                                >
                                    <div
                                        className="flex flex-col gap-1"
                                    >
                                        <div className="flex flex-row gap-1 items-center">
                                            <text className="text-gray-600">Focus</text>
                                        </div>
                                        <text className="text-xs text-gray-400">Tailored to analyze scientific papers, better answers.</text>
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
            </div>
            {!isSmallScreen &&
                <div 
                    className="flex flex-row gap-2 flex-grow justify-end items-center fixed right-12"
                >
                    <img
                        className="w-5 h-5"
                        src={FocusLogo} alt="Focus Logo"
                    />
                    <text
                        className="text-2xl font-semibold"
                    >
                        Focus
                    </text>
                </div>
            }
        </div>
    )
}