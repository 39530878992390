import * as React from 'react';

import { FiMessageSquare } from "react-icons/fi";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { HiMiniChatBubbleBottomCenter } from "react-icons/hi2";


import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import ListDivider from '@mui/joy/ListDivider';
import Input from '@mui/joy/Input';
import { Box, Stack } from '@mui/joy';

import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

import { CgRename } from "react-icons/cg";
import { MdDeleteOutline } from "react-icons/md";

import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';

// Import chat-bubble-bottom-center from heroicons

import axios from 'axios';

import '../index.css';

import { UserContext } from '../pages/Chat';
import { MainUserContext } from '../App';
import { Tooltip } from '@mui/material';

import { motion } from "framer-motion";

import { useNavigate } from 'react-router-dom';

function ChatBubbleBottomCenterIcon(props) {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
        </svg>
    )
}

function RenameChatModal({isOpen, setIsOpen, chatId}) {

    const [newChatName, setNewChatName] = React.useState('');
    
    const renameChat = ( newName ) => {
        axios.post(process.env.REACT_APP_API_URL + '/api/chats/rename', {
            conversation_id: chatId,
            conversation_name: newName
        }).then(response => {
        }).catch(error => {
        });
    }

    return (
        <React.Fragment>
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Sheet
            variant="outlined"
            sx={{ width: 800, borderRadius: 'md', p: 3, boxShadow: 'lg' }}
            >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                sx={{ fontWeight: 'lg', mb: 1, textAlign: 'center' }}
            >
                Rename chat
            </Typography>
            <form
                onSubmit={(e) => {
                e.preventDefault();
                renameChat(newChatName);
                setNewChatName('');
                setIsOpen(false);
            }}>
                <Input required
                    placeholder="New chat name"
                    value={newChatName}
                    onChange={(e) => setNewChatName(e.target.value)}
                    sx={{ width: '100%', mb: 2 }}
                />
            </form>
            </Sheet>
        </Modal>
        </React.Fragment>
    );
}

function ChatButtonMenu({ setIsOpen, chatId }) {

    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { setMessages } = React.useContext(UserContext);
    const { setCurrentChatId} = React.useContext(UserContext);
    const deleteChat = () => {
        setMessages([]);
        setCurrentChatId(null);
        axios.post(process.env.REACT_APP_API_URL + '/api/chats/delete', {
            conversation_id: chatId
        }).then(response => {
        }).catch(error => {
        });
    }

    return (
        <Dropdown>
            <MenuButton variant="plain" onClick={(e) => e.stopPropagation()}>
                <EllipsisHorizontalIcon
                    className='w-5 h-5 cursor-pointer hover:bg-white rounded-md'
                />
            </MenuButton>
            <Menu sx={{zIndex: '9999'}}>
                <MenuItem onClick={() => setIsOpen(true)} disabled={messageIsLoading}>
                    <ListItemDecorator>
                        <CgRename/>
                    </ListItemDecorator>
                    <text
                        className='text-gray-500 text-sm font-semibold'
                    >
                        Rename
                    </text>
                </MenuItem>
                <ListDivider/>
                <MenuItem variant='soft' color='danger' disabled={messageIsLoading}
                    onClick={() => deleteChat()}>
                    <ListItemDecorator>
                        <MdDeleteOutline/>
                    </ListItemDecorator>
                    <text
                        className='text-red-500 text-sm font-semibold'
                    >
                        Delete
                    </text>
                </MenuItem>
            </Menu>
        </Dropdown>
    )
}

function SnackbarWithDecorators({alertOpen, setAlertOpen}) {
  
    return (
      <React.Fragment>
        <Snackbar
          variant="soft"
          autoHideDuration={2000}
          color="success"
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
          endDecorator={
            <Button
              onClick={() => setAlertOpen(false)}
              size="sm"
              variant="soft"
              color="success"
            >
              Dismiss
            </Button>
          }
        >
          Chat selected successfully!
        </Snackbar>
      </React.Fragment>
    );
  }

function ChatButton({chat, chatName, chatId, setCurrentChatName}) {
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { currentChatId, setCurrentChatId, setSparkaiSidebarOpen } = React.useContext(UserContext);
    const [ buttonStyle, setButtonStyle ] = React.useState("bg-white");

    const [ chatHour, setChatHour ] = React.useState('');
    const [ chatDay, setChatDay ] = React.useState('');

    const navigate = useNavigate();

    React.useEffect(() => {
        const date = new Date(chat.updated_at);
        // Get hour in 12-hour format
        const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        setChatHour((hours < 10 ? '0' : '') + hours + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ' ' + (date.getHours() >= 12 ? 'PM' : 'AM'));
        // If the chat was started less than 8 days ago, show the days since the chat started
        const today = new Date();
        const diffTime = Math.abs(today - date);
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        // If the chat was started today, show today
        if (diffDays < 1) {
            setChatDay('Today');
        }
        else if (diffDays < 8) {
            setChatDay(Math.ceil(diffDays) + ' days ago');
        }
        // If the chat was started more than 8 days ago, show the date
        else {
            setChatDay(date.toDateString());
        }
    }, [chat]);
    
    React.useEffect(() => {
        if (chatId === currentChatId) {
            setButtonStyle("bg-gray-200");
        } else {
            setButtonStyle("bg-white");
        }
    }, [currentChatId]);

    const setChatName = (newChatName, newChatId) => {
        if (newChatId === currentChatId) {
            return;
        }
        setCurrentChatName(newChatName);
        navigate(`/chat?chat_id=${newChatId}`);
        if (window.innerWidth > 768) {
            setAlertOpen(true);
        } else {
            setSparkaiSidebarOpen(false);
        }
    }
    return (
        <>
        <Tooltip title={chatName} placement="right">
        <button 
            className={`w-[95%] min-h-8 rounded-md justify-between items-center ${buttonStyle} hover:bg-gray-100`}
        onClick={() => setChatName(chatName, chatId)} disabled={messageIsLoading}>
            <div 
                className="flex flex-row justify-between items-center p-1"
            >
                <div
                    className="flex flex-row items-center gap-2 max-w-[80%]"
                >
                    <div>
                        <HiMiniChatBubbleBottomCenter
                            className="w-4 h-4 text-gray-800"
                        />
                    </div>
                    <text
                        className="text-xs text-gray-800 font-semibold flex-grow overflow-x-hidden text-overflow-ellipsis whitespace-nowrap justify-start"
                        style={{flexGrow: 1, overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                        >
                        {chatName}
                    </text>
                </div>
                <ChatButtonMenu setIsOpen={setIsOpen} chatId={chatId}/>
                <RenameChatModal isOpen={isOpen} setIsOpen={setIsOpen} chatId={chatId}/>
            </div>
        </button>
        </Tooltip>
        </>
    )
}

export default function ChatList({filterName, setCurrentChatName, hidden, setSidebarOpen, height=35}) {

    const { chats, setChats } = React.useContext(UserContext);
    const { currentChatId, setCurrentChatId } = React.useContext(UserContext);
    const { userId, setUserId } = React.useContext(MainUserContext);
    const { sparkaiSidebarOpen, setSparkaiSidebarOpen } = React.useContext(UserContext);


    
    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/api/chat/' + userId)
        .then(response => {
            setChats(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    });

    // Helper function to group chats by time period
    const groupChatsByDate = (chats) => {
        const today = new Date();
        return chats.reduce((groups, chat) => {
            const date = new Date(chat.updated_at);
            const diffDays = Math.floor((today - date) / (1000 * 60 * 60 * 24));
            
            let group;
            if (diffDays === 0) {
                group = 'Today';
            } else if (diffDays === 1) {
                group = 'Yesterday';
            } else if (diffDays < 7) {
                group = `${diffDays} days ago`;
            } else if (diffDays < 30) {
                group = 'Last week';
            } else if (diffDays < 60) {
                group = 'A month ago';
            } else {
                group = date.toLocaleString('default', { month: 'long', year: 'numeric' });
            }

            if (!groups[group]) {
                groups[group] = [];
            }
            groups[group].push(chat);
            return groups;
        }, {});
    };
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, delay: 0.25 }}
            key={sparkaiSidebarOpen}
            className={`h-full overflow-y-auto ${hidden ? 'hidden' : ''}`}
        >
            {Object.entries(groupChatsByDate(chats))
                .map(([dateGroup, groupChats]) => (
                    <div key={dateGroup} className="w-full flex flex-col gap-1">
                        <div className="w-full px-4 py-2">
                            <text className="text-xs text-gray-500 font-medium">
                                {dateGroup}
                            </text>
                        </div>
                        {groupChats
                            .filter(chat => chat.conversation_name.toString().toLowerCase().includes(filterName.toString().toLowerCase()))
                            .map(chat => (
                                <React.Fragment key={chat.conversation_id}>
                                    <ChatButton 
                                        chat={chat} 
                                        chatName={chat.conversation_name} 
                                        chatId={chat.conversation_id} 
                                        setCurrentChatName={setCurrentChatName} 
                                        setCurrentChatId={setCurrentChatId}
                                    />
                                </React.Fragment>
                            ))
                        }
                    </div>
                ))
            }
        </motion.div>
    );
}