import ArticleCardList from "./Articles"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import remarkMath from "remark-math"
import rehypeKatex from "rehype-katex"
import rehypeRaw from "rehype-raw"
import React from "react"
import { MdScience } from "react-icons/md";
import { Skeleton } from "@mui/material"


import { LambdaContext } from "../pages/Lambda"
import FollowUpQuestions from "./followUpQuestions"
import EvidenceDashboard from "./EvidenceChart"

function ResultDashboard() {
    const { intent, answer, articles, followUpQuestions, isLoading } = React.useContext(LambdaContext);
    const [intentDetailsOpen, setIntentDetailsOpen] = React.useState(false);

    return (
        <div
            className="flex flex-col justify-center items-center w-full gap-3"
        >
            <div
                className="flex w-full"
            >
                <EvidenceDashboard />
            </div>
            <div
                className="flex flex-row w-full"
            >
                {isLoading ? (
                    <Skeleton
                        variant="rectangular"
                        width={200}
                        height={30}
                        className="rounded-xl"
                    />
                ) : (
                    <div
                        className="border border-gray-400 rounded-full p-2 justify-center items-center flex flex-row gap-2 relative cursor-pointer"
                        onMouseEnter={() => setIntentDetailsOpen(true)}
                        onMouseLeave={() => setIntentDetailsOpen(false)}
                    >
                        <MdScience
                            className="text-yellow-300 h-6 w-6"
                            />
                        <p
                            className="text-sm text-gray-700 font-semibold"
                            >
                            {intent}
                        </p>
                        <div
                            className="absolute top-11 p-2 bg-white rounded-lg shadow-md border border-gray-300 w-80
                            transition-all duration-300 ease-in-out"
                            hidden={!intentDetailsOpen}
                            >
                            <p  className="text-sm text-gray-500">
                                Based on your query, we've identified the intent as '{intent}'. We'll tailor our results to help you understand the answer better.
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <p
                className="text-md font-semibold text-gray-800 w-full"
            >
                Lambda Analysis
            </p>
            {isLoading ? (
                <div
                    className="flex flex-col w-full gap-2"
                >
                    <Skeleton
                        variant="rectangular"
                        width={900}
                        height={150}
                        className="rounded-xl"
                    />
                    <Skeleton
                        variant="rectangular"
                        width={750}
                        height={200}
                        className="rounded-xl"
                    />
                    <Skeleton
                        variant="rectangular"
                        width={800}
                        height={150}
                        className="rounded-xl"
                    />
                </div>
            ) : (
                <Markdown
                remarkPlugins={[[remarkGfm], [remarkMath]]} rehypePlugins={[[rehypeKatex], [rehypeRaw]]}
                className="text-xs md:text-base text-gray-800 md:leading-10"
                >
                    {answer}
                </Markdown>
                )    
            }
            <div
                className="flex flex-col w-full gap-2"
            >
                <p
                    className="text-md font-semibold text-gray-800"
                >
                    Expand your knowledge
                </p>
                { isLoading ? (
                    <div
                        className="flex flex-col w-full gap-2"
                    >
                        <Skeleton
                            variant="rectangular"
                            width={300}
                            height={25}
                            className="rounded-xl"
                        />
                        <Skeleton
                            variant="rectangular"
                            width={300}
                            height={25}
                            className="rounded-xl"
                        />
                        <Skeleton
                            variant="rectangular"
                            width={300}
                            height={25}
                            className="rounded-xl"
                        />
                    </div>
                ) : (
                    <FollowUpQuestions />
                )}
            </div>
            <ArticleCardList articles={articles} />
        </div>
    )
}

export default function ResultsLayout() {
    return (
        <div
            className="flex flex-col w-[60%] justify-center items-center absolute top-[200px]"
        >
            <ResultDashboard />
        </div>
    )
}

const test_articles = Array(10).fill({
    title: "Article 1",
    abstract: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis a elementum lectus. Ut pulvinar, eros id pulvinar interdum, ipsum augue luctus lacus, ut tempus odio ligula at sem. Maecenas quis lacus ex. Quisque vel velit vehicula, aliquet mi non, efficitur enim. Praesent lobortis, lacus sit amet sodales fermentum, nisl justo bibendum nisi, non efficitur lectus ipsum eu lorem. Aenean erat tortor, feugiat vel tortor at, efficitur ultricies dolor. Duis ac fermentum nisl. Morbi id ullamcorper eros.",
    keyExtract: "Ut pulvinar, eros id pulvinar interdum, ipsum augue luctus lacus, ut tempus odio ligula at sem. Maecenas quis lacus ex. Quisque vel velit vehicula, aliquet mi non, efficitur enim. Praesent lobortis, lacus sit amet sodales fermentum, nisl justo bibendum nisi, non efficitur lectus ipsum eu lorem. Aenean erat tortor, feugiat vel tortor at, efficitur ultricies dolor. Duis ac fermentum nisl. Morbi id ullamcorper eros.",
    year: 2021,
    authors: "Author L. Name, Author F. Name, Author M. Name, Author N. Name, Author O. Name",
    citations: 10,
})
