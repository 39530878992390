import React from 'react'
import { UserIcon, BoltIcon } from '@heroicons/react/20/solid'
import { TypeAnimation } from 'react-type-animation';

import { motion } from 'framer-motion'


export default function SparkleChatMessage({ user, text, time }) {
    return (
        <div
            className={`flex flex-col w-full h-auto p-2 my-2`}
        >
            <div
                className={`flex flex-row gap-2 w-full items-center
                    ${user === "Sparkle" ? "justify-start" : "justify-end"}`}
            >
                {user === "Sparkle" && (
                    <BoltIcon
                        className="h-6 w-6 text-emerald-300"
                    />
                )}
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                    className={`flex justify-start items-center rounded-3xl w-[75%] md:w-[65%] lg:w-[55%] p-3 shadow-sm
                        ${user === "Sparkle" ? "bg-emerald-200" : "bg-gray-200"}`}
                >
                {user !== "Sparkle" ? (        
                    <p
                        className="text-base text-gray-700"
                        >
                        {text}
                    </p>
                ) : (
                    <TypeAnimation
                        sequence={[
                            text,
                        ]}
                        wrapper="span"
                        speed={90}
                        className='text-base inline-block'
                        repeat={1}
                        cursor={false}
                    />
                )}
                </motion.div>
                {user !== "Sparkle" && (
                    <UserIcon
                    className="h-6 w-6 text-gray-400"
                    />
                )}
            </div>
        </div>
    )
}