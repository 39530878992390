import React from 'react';

import { SparkleContext } from '../pages/Sparkle';


export default function SparkleGuessedPeopleTag() {

    const { peopleGuessed } = React.useContext(SparkleContext);

    return (
        <div
            className="flex flex-row justify-center items-center w-full"
        >
            <p
                className="text-sm md:text-md text-center text-slate-700"
            >
                {peopleGuessed === 0 ? "Nadie ha adivinado todavía la palabra de hoy ¡Se el primero!" : `¡${peopleGuessed} personas han adivinado ya la palabra oculta!`}
            </p>
        </div>
    )
}