import React from "react"
import { motion } from "framer-motion";
import { FaCheckCircle } from "react-icons/fa";
import { BsSlashCircleFill } from "react-icons/bs";
import { IoCloseCircle } from "react-icons/io5";


export default function EvidenceDashboard({yes=70, no=10, maybe=20, nPapers=10}) {
    return (
        <div
            className="flex flex-row w-full h-full gap-4"
        >
            <EvidenceChart yes={yes} no={no} maybe={maybe} nPapers={nPapers} />
            <ReasonsChart nPapers={nPapers} />
        </div>
    )
}

function ReasonsChart({nPapers}) {
    const [ selectedTab, setSelectedTab ] = React.useState("yes")

    return (
        <div
            className="flex flex-col w-full h-[205px] gap-3 justify-center items-center
                border-2 border-gray-300 rounded-xl bg-white
                p-4
                shadow-md relative
                "
        >
            <BetaBadge />
            <PapersBadge nPapers={nPapers} />
            <p
                className="text-md font-semibold text-gray-800"
            >
                Reasons Chart
            </p>
            <hr className="w-full border-1 border-gray-300"/>
            <div
                className="flex flex-row gap-4 justify-start items-center w-full"
            >
                <div
                    className="flex flex-col gap-2 w-[40px]"
                >
                    <button
                        className={`border border-gray-300 rounded-lg p-2 ${selectedTab === "yes" ? "bg-green-300 text-white" : "bg-white text-green-300"}
                            flex justify-center items-center
                        `}
                        onClick={() => setSelectedTab("yes")}
                    >
                        <FaCheckCircle
                            className="text-sm w-4 h-4"
                        />
                    </button>
                    <button
                        className={`border border-gray-300 rounded-lg p-2 ${selectedTab === "maybe" ? "bg-gray-300 text-white" : "bg-white text-gray-300"}
                            flex justify-center items-center
                        `}
                        onClick={() => setSelectedTab("maybe")}
                    >
                        <BsSlashCircleFill
                            className="text-sm w-4 h-4"
                        />
                    </button>
                    <button
                        className={`border border-gray-300 rounded-lg p-2 ${selectedTab === "no" ? "bg-red-400 text-white" : "bg-white text-red-400"}
                            flex justify-center items-center
                        `}
                        onClick={() => setSelectedTab("no")}
                    >
                        <IoCloseCircle
                            className="text-sm w-5 h-5"
                        />
                    </button>
                </div>
                <div
                    className="flex flex-col gap-4 w-full"
                >
                    <p
                        className="text-sm font-semibold text-gray-800"
                    >
                        The reasons why the claim is {selectedTab === "yes" ? "supported" : selectedTab === "maybe" ? "inconclusive" : "refuted"} are because...
                    </p>
                </div>
            </div>
        </div>
    )
}


function EvidenceChart({yes, no, maybe, nPapers}) {
    return (
        <div
            className="flex flex-col w-full h-[205px] gap-3 justify-center items-center
                border-2 border-gray-300 rounded-xl bg-white
                p-4
                shadow-md relative
                "
        >
            <PapersBadge nPapers={nPapers} />
            <BetaBadge />
            <EvidenceTitle />
            <hr className="w-full border-1 border-gray-300"/>
            <EvidenceBar yes={yes} no={no} maybe={maybe} />
            <EvidenceLegend yes={yes} no={no} maybe={maybe} nPapers={nPapers} />
        </div>
    )
}

function EvidenceBar({yes, no, maybe}) {
    return (
        <div
            className="flex flex-row min-w-[90%] h-5 justify-start items-start gap-0 shadow-sm rounded-xl"
        >
            <YesBar yes={yes} />
            <MaybeBar maybe={maybe} />
            <NoBar no={no} />
        </div>
    )
}

function YesBar({yes}) {
    return (
        <motion.div
            initial={{width: 0}}
            animate={{width: `${yes}%`} }
            transition={{duration: 0.4, delay: 0.1}}
            className="flex justify-center items-center h-full bg-green-300 rounded-l-xl"
            style={{width: `${yes}%`}}
        >
            <p
                className="text-gray-800 font-semibold drop-shadow-md"
            >{yes}%</p>
        </motion.div>
    )
}

function MaybeBar({maybe}) {
    return (
        <motion.div
            initial={{width: 0}}
            animate={{width: `${maybe}%`} }
            transition={{duration: 0.4, delay: 0.4}}
            className="flex justify-center items-center h-full bg-gray-200"
            style={{width: `${maybe}%`}}
        >
            <p
                className="text-gray-800 font-semibold drop-shadow-md"
            >{maybe}%</p>
        </motion.div>
    )
}

function NoBar({no}) {
    return (
        <motion.div
            initial={{width: 0}}
            animate={{width: `${no}%`} }
            transition={{duration: 0.4, delay: 0.8}}
            className="flex justify-center items-center h-full bg-red-400 rounded-r-xl"
            style={{width: `${no}%`}}
        >
            <p
                className="text-white font-semibold drop-shadow-md"
            >{no}%</p>
        </motion.div>
    )
}

function EvidenceTitle() {
    return (
        <p
            className="text-md font-semibold text-gray-800"
        >
            Evidence Chart
        </p>
    )
}

function BetaBadge() {
    return (
        <p
            className="text-xs text-gray-600 absolute top-2 left-2 bg-gray-100 border border-gray-200 px-[6px] rounded-md"
        >
            BETA
        </p>
    )
}

function PapersBadge({nPapers}) {
    return (
        <p
            className="text-xs text-gray-600 absolute top-2 right-2"
        >
            {nPapers} papers analyzed
        </p>
    )
}

function EvidenceLegend({yes, no, maybe, nPapers}) {
    return (
        <div
                className="flex flex-col w-full gap-4"
        >
            <p
                className="text-sm font-semibold text-gray-800"
            >
                <span className="text-green-400">Yes</span> - The paper supports the claim <span className="bg-green-200 text-gray-800 px-1 rounded-md"> {nPapers * (yes/100)} out of {nPapers} </span>
            </p>
            <p
                className="text-sm text-gray-800"
            >
                <span className="text-gray-400">Maybe</span> - The paper is inconclusive <span className="bg-gray-200 text-gray-800 px-1 rounded-md"> {nPapers * (maybe/100)} out of {nPapers} </span>
            </p>
            <p
                className="text-sm text-gray-800"
            >
                <span className="text-red-400">No</span> - The paper refutes the claim <span className="bg-red-200 text-gray-800 px-1 rounded-md"> {nPapers * (no/100)} out of {nPapers} </span>
            </p>
        </div>
    )
}