import SparkleChat from "../sparkle_components/SparkleChat"
import SparkleGuessedPeopleTag from "../sparkle_components/SparkleGuessedPeopleTag"
import SparkleInput from "../sparkle_components/SparkleInput"
import SparkleTopHeader from "../sparkle_components/SparkleTopHeader"
import SparkleWordModule from "../sparkle_components/SparkleWordModule"

import { SparkleWordHistory } from "../sparkle_components/SparkleWordModule"

import { getWordOfTheDay, getPeopleGuessed,
    getUserProfile, createNewGuessing, autosolveWord } from "../sparkle_components/helpers"

import React from 'react'
import LengthErrorModal from "../sparkle_components/modals/LengthError"
import HasWonModal from "../sparkle_components/modals/HasWonModal"
import GameWonTimer from "../sparkle_components/GameWonTimer"
import Cookies from 'js-cookie';
import axios from "axios"


export const SparkleContext = React.createContext(null);

const testMessages = [
    {
        user: "Sparkle",
        text: "Hello, how can I help you today?",
        time: "12:00"
    },
    {
        user: "User",
        text: "I need help with my account. I can't seem to log in. This is a test message to see how the chat looks. I need help with my account. I can't seem to log in. This is a test message to see how the chat looks.",
        time: "12:01"
    },
    {
        user: "Sparkle",
        text: "I'm sorry to hear that. Let me help you with that. Can you please provide me with your email address?",
        time: "12:02"
    },
    {
        user: "User",
        text: "Sure, my email address is test@gmail.com. I need help with my account. I can't seem to log in. This is a test message to see how the chat looks.",
        time: "12:03"
    },
    {
        user: "Sparkle",
        text: "Thank you. Let me check your account.",
        time: "12:04"
    },
    {
        user: "Sparkle",
        text: "I have found your account. It seems that you have entered the wrong password. Please try logging in again.",
        time: "12:05"
    },
]


export default function Sparkle() {

    const [peopleGuessed, setPeopleGuessed] = React.useState(0)
    const [wordOfTheDay, setWordOfTheDay] = React.useState('')
    const [messages, setMessages] = React.useState([])
    const [userProfile, setUserProfile] = React.useState(null)
    const [historyOpen, setHistoryOpen] = React.useState(false)
    const [wordHistory, setWordHistory] = React.useState([])
    const [guessing, setGuessing] = React.useState([])
    const [isSparkleTyping, setIsSparkleTyping] = React.useState(false)
    const [hasWon, setHasWon] = React.useState(false)
    const [disableInput, setDisableInput] = React.useState(false)

    /**
     * Error modals
     */

    const [lengthErrorModalOpen, setLengthErrorModalOpen] = React.useState(false)
    const [hasWonModalOpen, setHasWonModalOpen] = React.useState(false)

    /****************************************/

    /**
     * Get cookies
     */

    React.useEffect(() => {
        const userGameWon = Cookies.get('gameWon');
        const dayWon = Cookies.get('dayWon');

        if (userGameWon === 'true' && dayWon === new Date().toISOString().split('T')[0]) {
            setHasWon(true);
        }

        // If day won is different from today, reset the game
        if (dayWon !== new Date().toISOString().split('T')[0]) {
            Cookies.remove('gameWon');
            Cookies.remove('dayWon');
        }
    }, []);

    /**
     * TODO: Set hasWonModalOpen to true when the user has won
     * 
     * @param {boolean} hasWon - The state of the modal
     * 
     * @returns {JSX.Element} The modal component
     */

    React.useEffect(() => {
        const userGameWon = Cookies.get('gameWon');
        if (hasWon && userGameWon !== 'false') {
            setHasWonModalOpen(true);
        }
    }, [hasWon]);
    
    /**
     * TODO: Use the getWordOfTheDay function to get the word of the day
     */
    React.useEffect(() => {
        if (wordOfTheDay === '') {
            console.log('Getting word of the day');
            axios.get(process.env.REACT_APP_API_URL + '/api/sparkle/word').then((response) => {
                console.log('[NEW] Word of the day:', response);
            });
            getWordOfTheDay().then((response) => {
                console.log('Word of the day:', response);
                setWordOfTheDay(response.word);
            });
        }
    }, []);

    /**
     * TODO: Use the getPeopleGuessed function to get the number of people that have 
     * guessed the word of the day every 20 seconds with an interval
     */
    React.useEffect(() => {
        const interval = setInterval(() => {
            getPeopleGuessed().then((response) => {
                console.log('People guessed:', response);
            });
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    /**
     * TODO: Create a guessing using the createNewGuessing function
     */

    React.useEffect(() => {
        console.log('Word of the day for guessing:', wordOfTheDay);
        if (wordOfTheDay !== '') {
            createNewGuessing(wordOfTheDay, hasWon).then((response) => {
                setGuessing(response);
            });
        }
    }, [wordOfTheDay]);

    /**
     * TODO: Use the getUserProfile function to get the user profile
     */

    React.useEffect(() => {
        getUserProfile().then((response) => {
            console.log('User profile:', response);
        });
    }, []);

    const contextValues = {
        messages: messages,
        setMessages: setMessages,
        wordOfTheDay: wordOfTheDay,
        setWordOfTheDay: setWordOfTheDay,
        peopleGuessed: peopleGuessed,
        setPeopleGuessed: setPeopleGuessed,
        userProfile: userProfile,
        setUserProfile: setUserProfile,
        historyOpen: historyOpen,
        setHistoryOpen: setHistoryOpen,
        wordHistory: wordHistory,
        setWordHistory: setWordHistory,
        guessing: guessing,
        setGuessing: setGuessing,
        isSparkleTyping: isSparkleTyping,
        setIsSparkleTyping: setIsSparkleTyping,
        lengthErrorModalOpen: lengthErrorModalOpen,
        setLengthErrorModalOpen: setLengthErrorModalOpen,
        hasWon: hasWon,
        setHasWon: setHasWon,
        disableInput: disableInput,
        setDisableInput: setDisableInput,
        hasWonModalOpen: hasWonModalOpen,
        setHasWonModalOpen: setHasWonModalOpen
    }

    return (
        <SparkleContext.Provider value={contextValues}>
        <div
            className="h-screen w-screen flex justify-center items-center bg-emerald-25"
        >
            <div
                className="flex flex-col justify-between items-center w-11/12 md:w-8/12 h-screen"
            >
                <div
                    className="flex flex-col justify-end items-center h-[8%] w-full py-2 z-10"
                >
                    <SparkleTopHeader />
                </div>
                <div
                    className="flex flex-col justify-center items-center h-[17%] w-full"
                >
                    <SparkleWordModule hidden={historyOpen} />
                </div>
                <div
                    className={`flex-col justify-center items-center h-[80%] md:h-[60%] w-screen overflow-y-auto
                                ${historyOpen ? 'hidden' : 'flex'}
                        `}
                >
                    <SparkleChat />
                </div>
                <div
                    className={`flex-col justify-center items-center h-[80%] md:h-[60%] w-screen overflow-y-auto
                                ${historyOpen ? 'flex' : 'hidden'}
                        `}
                >
                    <SparkleWordHistory words={wordHistory} />
                </div>
                <div
                    className="flex flex-col justify-start items-center h-[12%] md:h-[15%] w-full gap-2 relative"
                >
                    <div
                        className="flex w-full absolute top-[-25px] left-[150px]"
                    >
                        <p
                            className="text-md font-semibold text-emerald-600"
                            hidden={!isSparkleTyping}
                        >
                            Sparkle está escribiendo...
                        </p>
                    </div>
                    { !hasWon ?(
                        <SparkleInput />
                    ) : (
                        <GameWonTimer />
                    )}
                    <SparkleGuessedPeopleTag />
                </div>
            </div>
        </div>
        <LengthErrorModal isOpen={lengthErrorModalOpen} setIsOpen={setLengthErrorModalOpen} />
        <HasWonModal isOpen={hasWonModalOpen} setIsOpen={setHasWonModalOpen} />
        </SparkleContext.Provider>
    )
}