import axios from 'axios';


export async function submitQuery(value, setIntent, setAnswer, setArticles, setFollowUpQuestions, setSubmitted, setInputValue, setIsLoading) {
    setSubmitted(true);
    setIsLoading(true);

    // Reset all states
    setIntent(null);
    setAnswer(null);
    setArticles([]);
    setFollowUpQuestions([]);
    setInputValue(value);

    console.log('[LambdaInput] Submitting query:', value);
    console.log('API URL:', process.env.REACT_APP_API_URL + '/api/ia/lambda/answer');

    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/ia/lambda/answer', {
        query: value,
    });

    console.log(response);
    setIntent(response.data.intent);
    setAnswer(response.data.text);
    setArticles(response.data.results);
    console.log('Follow-up questions:', response.data.follow_up_questions);
    setFollowUpQuestions(response.data.follow_up_questions.followup_questions);

    setIsLoading(false);
}
