import { HiBars3 } from "react-icons/hi2";
import { IoSearch } from "react-icons/io5";
import { motion } from "framer-motion";
import React from "react";
import ChatSearch from "./ChatSearch";
import { IoMdAdd } from "react-icons/io";
import ChatList from './ChatButton';
import Tooltip from '@mui/joy/Tooltip';
import { FaHeart } from "react-icons/fa6";
import { BiSolidErrorAlt } from "react-icons/bi";
import {ProfileMenu} from "./PersistentDrawer";

import SparkaiLogoBlack from "../assets/sparkai_logo_blackwhite.png";
import FocusLogoBlack from "../assets/focus_logo_black.png";

import { UserContext } from "../pages/Focus";
import { MainUserContext } from "../App";


export default function FocusSidebar({ sidebarOpen, setSidebarOpen }) {
    const [filterName, setFilterName] = React.useState('');
    const { setCurrentChatName } = React.useContext(UserContext);
    const { setMessages } = React.useContext(UserContext);
    const { setCurrentChatId } = React.useContext(UserContext);
    const { setPaper } = React.useContext(UserContext);

    const { user } = React.useContext(MainUserContext);
    const [mail, setMail] = React.useState('error loading mail');

    React.useEffect(() => {
        if (user) {
            setMail(user.mail);
        }
    }, [user]);

    return (
        <div
            className={`flex flex-col h-full fixed top-0 left-0
                    ${sidebarOpen ? 'w-full sm:w-[300px]' : 'w-20'} transition-all duration-300 z-50
                `}
        >
            <div
                className={`justify-start fixed
                            items-center
                            h-[10%] w-full ${sidebarOpen ? 'hidden' : 'flex sm:hidden'}
                        `}
            >
                <span
                    className={`flex hover:bg-gray-100 rounded-full p-2
                                absolute left-4 cursor-pointer
                            `}
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                    <HiBars3
                        className={`text-3xl text-black
                                    w-6 h-6
                                `}
                    />
                </span>
            </div>
            <div
                className={`flex-col w-full h-full border-r-2 bg-white
                            ${sidebarOpen ? '' : 'hidden sm:flex'}
                    `}
            >
                <div
                    className={`flex justify-start relative
                                items-center
                                h-[10%] w-full
                            `}
                >
                    <span
                        className={`flex hover:bg-gray-100 rounded-full p-2
                                    absolute left-4 cursor-pointer
                                `}
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                        <HiBars3
                            className={`text-3xl text-black
                                        w-6 h-6
                                    `}
                        />
                    </span>
                </div>
                <div
                    className={`flex flex-col justify-center items-center
                                h-[10%] md:h-[10%] w-full ${sidebarOpen ? 'gap-1' : 'gap-6'}
                            `}
                >
                    <div
                        className={`flex justify-center items-center w-11/12
                                ${sidebarOpen ? 'hover:bg-gray-100': ''}
                                cursor-pointer    
                                rounded-full
                                py-2
                            `}
                    >
                        <span
                            className={`flex rounded-full p-2
                                        absolute left-[22px] ${sidebarOpen ? '' : 'hover:bg-gray-100'}
                                    `}
                            onClick={() => {
                                window.open('/sparkai', '_blank');
                            }}
                        >
                            <img
                                src={SparkaiLogoBlack}
                                className={`w-[15px] h-[16px]`}
                            />
                        </span>
                        <motion.div
                            className={`flex flex-col justify-center items-start 
                                        w-full ml-[45px]
                                    `}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3, delay: 0.25 }}
                            key={sidebarOpen}
                            onClick={() => {
                                window.open('/sparkai', '_blank');
                            }}
                        >
                            <p
                                className={`text-gray-700 text-sm font-semibold
                                            cursor-pointer
                                        `}
                                hidden={!sidebarOpen}
                            >
                                SparkAI
                            </p>
                        </motion.div>
                    </div>
                    <div
                        className={`flex justify-center items-center w-11/12
                                ${sidebarOpen ? 'hover:bg-gray-100': ''}
                                cursor-pointer    
                                rounded-full
                                py-2
                            `}
                    >
                        <span
                            className={`flex rounded-full p-2
                                        absolute left-[21px] ${sidebarOpen ? '' : 'hover:bg-gray-100'}
                                    `}
                            onClick={() => {
                                console.log('Focus clicked');
                            }}
                        >
                            <img
                                src={FocusLogoBlack}
                                className={`w-4 h-4`}
                            />
                        </span>
                        <motion.div
                            className={`flex flex-col justify-center items-start 
                                        w-full ml-[45px]
                                    `}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3, delay: 0.25 }}
                            key={sidebarOpen}
                            onClick={() => {
                                console.log('Focus clicked');
                            }}
                        >
                            <p
                                className={`text-gray-700 text-sm font-semibold
                                            cursor-pointer
                                        `}
                                hidden={!sidebarOpen}
                            >
                                Focus
                            </p>
                        </motion.div>
                    </div>
                </div>
                <div
                    className={`flex flex-col justify-center items-center
                                h-[10%] w-full
                            `}
                >
                    <div
                        className={`flex justify-center items-center w-11/12 py-2
                                ${sidebarOpen ? 'hover:bg-gray-100': ''}
                                cursor-pointer    
                                rounded-full
                            `}
                    >
                        <span
                            className={`flex hover:bg-gray-100 rounded-full p-2
                                        absolute left-[18px]
                                    `}
                            onClick={() => {
                                setCurrentChatId(null);
                                setPaper(null);
                                setCurrentChatName(null);
                                setSidebarOpen(false);
                            }}
                        >
                            <IoMdAdd
                                className={`text-black cursor-pointer
                                            w-5 h-5 ml-[3px]
                                        `}
                            />
                        </span>
                        <motion.div
                            className={`flex flex-col justify-center items-start pl-12
                                        w-full
                                    `}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3, delay: 0.25 }}
                            key={sidebarOpen}
                            onClick={() => {
                                setPaper(null);
                                setCurrentChatName(null);
                                setCurrentChatId(null);
                                setSidebarOpen(false);
                            }}
                        >
                            <p
                                className={`text-gray-700 text-sm
                                            cursor-pointer
                                        `}
                                hidden={!sidebarOpen}
                            >
                                Search for another paper
                            </p>
                        </motion.div>
                    </div>
                </div>
                <div
                    className="flex flex-col w-full gap-3"
                >
                    <ChatSearch 
                        hidden={!sidebarOpen}
                        setFilterName={setFilterName}
                    />
                    <ChatList
                        hidden={!sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        filterName={filterName} setCurrentChatName={setCurrentChatName}
                    />
                </div>
                {/**
                 * Feedback and bug report buttons
                 */}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.5 }}
                    key={sidebarOpen}
                    hidden={!sidebarOpen}
                    className={`flex flex-col justify-end items-center w-full p-2 h-[25%]
                                ${!sidebarOpen ? 'hidden' : ''}
                        `}
                >
                    <div
                        className="flex justify-start items-center w-full"
                    >
                        <div
                            className="flex flex-row w-9/12 justify items-center gap-1 text-gray-500"
                            >
                                <ProfileMenu/>
                            <text
                                className="text-xs"
                            >
                                {mail}
                            </text>
                        </div>
                    </div>
                    <div
                        className="flex flex-row gap-1 justify-center items-start w-[80%] my-[5%]"
                    >
                        <Tooltip title="Give feedback!" position="right">
                            <button
                                className="h-10 flex flex-row gap-2 items-center px-3 hover:bg-gray-100 rounded-full"
                                onClick={() => window.open('https://forms.gle/fs8mWTtWNxmyjR6Y7', '_blank')}
                            >
                                <FaHeart
                                    className="w-4 h-4 text-gray-600 cursor-pointer"
                                />
                                <text
                                    className="text-xs text-gray-600"
                                >
                                    Give feedback
                                </text>
                            </button>
                        </Tooltip>
                        <Tooltip title="Report a bug" position="right">
                            <button
                                className="h-10 flex flex-row gap-2 items-center px-3 hover:bg-gray-100 rounded-full"
                                onClick={() => window.open('https://forms.gle/MoNRxo8xw9qtg38n6', '_blank')}
                            >
                                <BiSolidErrorAlt
                                    className="w-4 h-4 text-gray-600 cursor-pointer"
                                />
                                <text
                                    className="text-xs text-gray-600"
                                >
                                    Report a bug
                                </text>
                            </button>
                        </Tooltip>
                    </div>
                </motion.div>
                {/**
                 * -----------------------------------------------
                 */}
            </div>
        </div>
    )
}