import { Stack } from '@mui/joy';
import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Set up the workerSrc for pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

// Add this error boundary component at the top of the file
class PDFErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('PDF Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex justify-center items-center h-screen">
          <p className="text-gray-500">Failed to load PDF</p>
        </div>
      );
    }

    return this.props.children;
  }
}

const PDFViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [hasError, setHasError] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setHasError(false);
  };

  // Construct the proxy URL
  const pdfProxyUrl = `${process.env.REACT_APP_API_URL}/api/focus/pdf?url=${encodeURIComponent(url)}`;

  // Fetch the PDF file with Authorization header
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(pdfProxyUrl, {
          method: 'GET',
          headers: {
            'Authorization': 'c46801db-29ee-408c-bf24-6b16ba8131ac',
          }
        });

        if (!response.ok) {
          throw new Error(`Failed to load PDF: ${response.statusText}`);
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        setPdfBlobUrl(blobUrl);
        setHasError(false);
      } catch (error) {
        console.error('Error loading PDF:', error);
        setHasError(true);
      }
    };

    fetchPdf();
  }, [pdfProxyUrl]);

  return (
    <div
      className="flex flex-col w-full md:w-[50%] items-center justify-center absolute left-0 md:left-[14vw] z-0 min-h-screen"
    >
      <Stack
        direction={'row'}
        sx={{ 
          position: 'fixed', 
          bottom: { xs: 20, md: 75 }, 
          left: '50%', 
          transform: 'translateX(-50%)', 
          zIndex: 10, 
          bgcolor: 'white', 
          borderRadius: 10, 
          padding: { xs: 0.5, md: 1 }, 
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}
        gap={2}
      >
        <Typography variant="body2" sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
          Total Pages: {numPages}
        </Typography>
      </Stack>

      {pdfBlobUrl ? (
        <PDFErrorBoundary>
          <Document
            className="w-full md:w-[100vw] min-h-screen flex flex-col items-center px-2 md:px-0"
            file={pdfBlobUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => {
              console.error('Error loading PDF:', error);
              setHasError(true);
            }}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <PDFErrorBoundary key={`error_boundary_${index + 1}`}>
                <Page 
                  key={`page_${index + 1}`} 
                  pageNumber={index + 1} 
                  scale={window.innerWidth < 768 ? 0.8 : 1.2}
                  width={Math.min(window.innerWidth < 768 ? window.innerWidth - 32 : 800, 1000)}
                  style={{ margin: '10px 0' }} 
                  error={(error) => {
                    console.error('Error rendering page:', error);
                    return (
                      <div className="flex justify-center items-center p-4">
                        <p className="text-gray-500 text-sm">Failed to load page {index + 1}</p>
                      </div>
                    );
                  }}
                />
              </PDFErrorBoundary>
            ))}
          </Document>
        </PDFErrorBoundary>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <p className="text-gray-500 text-sm">Loading PDF...</p>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
