import { guessWord, sendMessageToAI } from "./helpers";

import { SparkleContext } from "../pages/Sparkle";

import Cookies from 'js-cookie';
import React from 'react';

import { motion } from 'framer-motion';


export default function SparkleInput() {

    const { guessing, setGuessing } = React.useContext(SparkleContext);
    const { wordOfTheDay } = React.useContext(SparkleContext);
    const { wordHistory, setWordHistory } = React.useContext(SparkleContext);
    const { messages, setMessages } = React.useContext(SparkleContext);
    const { isSparkleTyping, setIsSparkleTyping } = React.useContext(SparkleContext);
    const { lengthErrorModalOpen, setLengthErrorModalOpen } = React.useContext(SparkleContext);
    const { hasWon, setHasWon } = React.useContext(SparkleContext);
    const { disableInput, setDisableInput } = React.useContext(SparkleContext);
    const [newGuess, setNewGuess] = React.useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisableInput(true);
        // Async sleep to simulate typing
        const userNewGuess = newGuess;
        setNewGuess('');
        const tempMessages = [
            ...messages,
            {
                role: "user",
                text: userNewGuess,
                time: new Date().toISOString()
            }
        ]
        setMessages(tempMessages);
        
        await new Promise(r => setTimeout(r, 1000));
        setIsSparkleTyping(true);
        const endpointResponse = await sendMessageToAI(
            userNewGuess,
            messages,
            wordOfTheDay
        );
        console.log('Endpoint response:', endpointResponse);
        const newMessages = endpointResponse.newMessages;
        const isGuessing = endpointResponse.isGuessing;

        if (!isGuessing) {
            console.log('User is not guessing for message:', userNewGuess);
        } else {
            console.log('User is guessing for message:', userNewGuess);
            const result = await guessWord(
                isGuessing,
                wordOfTheDay
            );
            const guessing = result.guessing;
            const hasWonAux = result.hasWon;
            console.log('Result:', result);
            console.log('Guessing:', guessing);
            console.log('Has won:', hasWonAux);
            if (guessing === "LENGTH_ERROR") {
                console.log('Length error for message:', userNewGuess);
                setLengthErrorModalOpen(true);
            } else {
                setHasWon(hasWonAux);
                Cookies.set('gameWon', hasWonAux);
                Cookies.set('dayWon', new Date().toISOString().split('T')[0]);
                setGuessing(guessing);
                setWordHistory([
                    ...wordHistory,
                    guessing
                ]);
            }

        }

        setIsSparkleTyping(false);
        setDisableInput(false);
        setMessages(newMessages);
    }

    return (
        <form
            className="w-full flex justify-center items-center"
            onSubmit={handleSubmit}
        >
            <motion.input
                className="rounded-full w-11/12 md:w-9/12 bg-white focus:ring-2 focus:ring-emerald-300 focus:outline-none shadow-md p-4"
                placeholder="Ask anything"
                value={newGuess}
                onChange={(e) => setNewGuess(e.target.value)}
                required
                disabled={disableInput || hasWon}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                whileFocus={{ scale: 1.05 }}
                whileHover={{ scale: 1.05 }}
            />
        </form>
    )
}