


/**
 * This is a modal that will be displayed when the user tries to submit a word that has
 * a length different from the word of the day.
 * 
 * @param {boolean} isOpen - The state of the modal
 * 
 * @returns {JSX.Element} The modal component
 */
export default function LengthErrorModal({ isOpen, setIsOpen }) {
    return (
        <div
            className={`fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50 ${isOpen ? 'block' : 'hidden'}`}
        >
            <div
                className="bg-white w-1/2 p-4 rounded-lg shadow-lg"
            >
                <p
                    className="text-2xl font-bold text-center"
                >
                    ¡Atención!
                </p>
                <p
                    className="text-lg text-center"
                >
                    La palabra que intentaste adivinar no tiene la misma longitud que la palabra del día. Vuelve a intentarlo con otra! :)
                </p>
                <div
                    className="flex justify-center items-center w-full"
                >
                    <button
                        className="bg-emerald-500 text-white p-2 rounded-lg mt-4 hover:bg-emerald-600 hover:shadow-md"
                        onClick={() => setIsOpen(false)}
                    >
                        De acuerdo
                    </button>
                </div>
            </div>
        </div>
    )
}