import { HiBars3 } from "react-icons/hi2";
import { IoSearch } from "react-icons/io5";
import { motion } from "framer-motion";
import React from "react";
import ChatSearch from "./ChatSearch";
import { IoMdAdd } from "react-icons/io";
import ChatList from './ChatButton';
import Tooltip from '@mui/joy/Tooltip';
import { FaHeart } from "react-icons/fa6";
import { BiSolidErrorAlt } from "react-icons/bi";
import {ProfileMenu} from "./PersistentDrawer";

import SparkaiLogoBlack from "../assets/sparkai_logo_blackwhite.png";
import FocusLogoBlack from "../assets/focus_logo_black.png";

import { UserContext } from "../pages/Chat";
import { MainUserContext } from "../App";
import { useNavigate, useSearchParams } from 'react-router-dom';


export default function LambdaSidebar({ sidebarOpen, setSidebarOpen }) {
    const [filterName, setFilterName] = React.useState('');
    const { setCurrentChatName } = React.useContext(UserContext);
    const { setMessages } = React.useContext(UserContext);
    const { setCurrentChatId } = React.useContext(UserContext);

    const { user } = React.useContext(MainUserContext);
    const [mail, setMail] = React.useState('error loading mail');
    const navigate = useNavigate();

    React.useEffect(() => {

        if (user) {
            setMail(user.mail);
        }
    }, [user]);

    return (
        <div
            className={`flex flex-col h-screen fixed top-0 left-0 
                ${sidebarOpen 
                    ? 'w-full sm:w-[300px] border-r-2 bg-white' 
                    : 'w-[70px] sm:border-r-2 sm:bg-white bg-transparent'
                } 
                transition-all duration-300 z-50`}
        >
            {/* Header section */}
            <div className="flex flex-col gap-2 p-2">
                {/* Hamburger menu */}
                <div className="flex items-center pl-3 pt-2">
                    <HiBars3
                        className="w-5 h-5 cursor-pointer hover:bg-gray-100 rounded-full"
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                    />
                </div>

                {/* SparkAI, Focus, and New Chat buttons - hidden on mobile when collapsed */}
                <div className={`${!sidebarOpen && 'hidden sm:block'}`}>
                    {/* SparkAI button */}
                    <button 
                        className="flex items-center gap-3 pl-[19px] py-2 w-full hover:bg-gray-100 rounded-lg"
                        onClick={() => {
                            console.log('clicked');
                        }}
                    >
                        <img
                            src={SparkaiLogoBlack}
                            className="w-[15px] h-[15px]"
                            alt="SparkAI"
                        />
                        {sidebarOpen && (
                            <span className="text-sm text-gray-700 font-semibold">
                                SparkAI
                            </span>
                        )}
                    </button>

                    {/* Focus button */}
                    <button 
                        className="flex items-center gap-3 pl-[18px] py-2 w-full hover:bg-gray-100 rounded-lg"
                        onClick={() => {
                            window.open('/focus', '_blank');
                        }}
                    >
                        <img
                            src={FocusLogoBlack}
                            className="w-4 h-4"
                            alt="Focus"
                        />
                        {sidebarOpen && (
                            <span className="text-sm text-gray-700 font-semibold">
                                Focus
                            </span>
                        )}
                    </button>

                    {/* New chat button */}
                    <button 
                        className="flex items-center gap-3 px-4 py-2 w-full mt-2 hover:bg-gray-100 rounded-lg"
                        onClick={() => {
                            setMessages([]);
                            navigate('/chat');
                            setSidebarOpen(false);
                        }}
                    >
                        <IoMdAdd className="w-5 h-5 text-black" />
                        {sidebarOpen && (
                            <span className="text-sm text-gray-700">
                                Start a new chat
                            </span>
                        )}
                    </button>
                </div>
            </div>

            {/* Chat section - fills remaining space */}
            <div className={`${!sidebarOpen && 'hidden sm:flex'} flex-1 flex flex-col min-h-0`}>
                {/* Search bar */}
                <div className="">
                    <ChatSearch 
                        hidden={!sidebarOpen}
                        setFilterName={setFilterName}
                    />
                </div>

                {/* Chat list - scrollable */}
                <div className="flex-1 overflow-y-auto ml-3">
                    <ChatList
                        hidden={!sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        filterName={filterName} 
                        setCurrentChatName={setCurrentChatName}
                    />
                </div>
            </div>

            {/* Footer section */}
            <div className={`${!sidebarOpen && 'hidden sm:block'} border-t border-gray-200 mt-auto`}>
                {/* Account info */}
                <div className="flex items-center px-4 py-2">
                    <div className={`flex items-center gap-2 text-gray-500 ${!sidebarOpen && 'w-full justify-center'}`}>
                        <ProfileMenu/>
                        {sidebarOpen && (
                            <span className="text-xs truncate">
                                {mail}
                            </span>
                        )}
                    </div>
                </div>

                {/* Feedback buttons */}
                <div className="flex justify-center gap-2 px-4 py-2">
                    <Tooltip title="Give feedback!" position="right">
                        <button className="flex items-center gap-2 px-3 hover:bg-gray-100 rounded-full">
                            <FaHeart className="w-4 h-4 text-gray-600"/>
                            {sidebarOpen && (
                                <span className="text-xs text-gray-600">Give feedback</span>
                            )}
                        </button>
                    </Tooltip>
                    <Tooltip title="Report a bug" position="right">
                        <button className="flex items-center gap-2 px-3 hover:bg-gray-100 rounded-full">
                            <BiSolidErrorAlt className="w-4 h-4 text-gray-600"/>
                            {sidebarOpen && (
                                <span className="text-xs text-gray-600">Report a bug</span>
                            )}
                        </button>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}