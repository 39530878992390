import { Badge, Tooltip } from "@mui/material";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";
import '../index.css';
import SparkAI_logo from '../assets/sparkai_new_only_logo.png';
import Typography from "@mui/joy/Typography";
import { useMediaQuery } from '@mui/material';
import { Stack } from "@mui/joy";
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ShareIcon } from '@heroicons/react/20/solid';
import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Select } from '@mui/base/Select';
import { Option } from '@mui/base/Option';
import { UserContext } from "../pages/Chat";
import { FaBolt } from "react-icons/fa6";
import axios from 'axios';
import { MainUserContext } from "../App";


export default function TopHeader({isOpen, toggleDrawer, currentChatName, marginLeft}) {

    const isSmallScreen = useMediaQuery('(max-width: 1000px)');
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { model, setModel } = React.useContext(UserContext);
    const { sparkaiSidebarOpen, setSparkaiSidebarOpen } = React.useContext(UserContext);
    const [ modelMenuOpen, setModelMenuOpen ] = React.useState(false);
    const [closeTimeout, setCloseTimeout] = React.useState(null);
    const [shareMenuOpen, setShareMenuOpen] = React.useState(false);
    const [shareUrl, setShareUrl] = React.useState('');
    const [copied, setCopied] = React.useState(false);
    const [isShared, setIsShared] = React.useState(false);
    const [shareError, setShareError] = React.useState(null);
    const { currentChatId } = React.useContext(UserContext);
    const { userId } = React.useContext(MainUserContext);

    // Check if chat is already shared when component mounts or when currentChatId changes
    React.useEffect(() => {
        const checkIfShared = async () => {
            if (!currentChatId) return;

            try {
                const response = await axios.get(`/api/chat/is-shared/${currentChatId}`);
                console.log("Response:", response.data);
                if (response.data.status === 'success') {
                    const isCurrentChatShared = response.data.is_shared;
                    setIsShared(isCurrentChatShared);
                    
                    if (isCurrentChatShared) {
                        const shareId = response.data.share_id;
                        setShareUrl(`${window.location.origin}/shared/${shareId}`);
                    }
                }
            } catch (err) {
                console.error('Failed to check share status:', err);
            }
        };

        checkIfShared();
    }, [currentChatId, userId]);

    const handleShareClick = async () => {
        console.log('Share button clicked. Current state:', { isShared, shareMenuOpen });
        
        if (isShared) {
            console.log('Chat is already shared, toggling menu');
            setShareMenuOpen(prev => !prev);
            return;
        }

        console.log('Attempting to share chat...');
        try {
            const response = await axios.post('/api/shared-chats', {
                conversation_id: currentChatId,
                created_by: userId,
                expires_at: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString() // 1 month from now
            });
            
            console.log('Share API response:', response.data);
            
            if (response.data.status === 'success') {
                setShareUrl(response.data.share_url);
                setIsShared(true);
                setShareMenuOpen(true);
                setShareError(null);
                console.log('Share successful, menu should open');
            } else {
                setShareError(response.data.message);
                console.log('Share failed:', response.data.message);
            }
        } catch (err) {
            console.error('Failed to share:', err);
            setShareError('Failed to share chat. Please try again.');
        }
    };

    const handleUnshare = async () => {
        try {
            const shareId = new URL(shareUrl).pathname.split('/').pop();
            const response = await axios.delete(`/api/shared-chats/${shareId}`, {
                data: { user_id: userId }
            });

            if (response.data.status === 'success') {
                setIsShared(false);
                setShareMenuOpen(false);
                setShareUrl('');
            }
        } catch (err) {
            console.error('Failed to unshare:', err);
            setShareError('Failed to unshare chat. Please try again.');
        }
    };

    const handleModelChange = (newModel) => {
        console.log("model changed to", newModel);
        setModel(newModel);
        setModelMenuOpen(false);
    }

    const handleMouseLeave = () => {
        const timeout = setTimeout(() => {
            setModelMenuOpen(false);
        }, 300); // 300ms delay
        setCloseTimeout(timeout);
    };

    const handleMouseEnter = () => {
        if (closeTimeout) {
            clearTimeout(closeTimeout);
        }
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(shareUrl);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    // Add click outside handler to close the menu
    const shareMenuRef = React.useRef(null);

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (shareMenuRef.current && !shareMenuRef.current.contains(event.target)) {
                console.log('Click outside detected, closing share menu');
                setShareMenuOpen(false);
                setCopied(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div 
            className="flex flex-row h-14 justify-start items-center gap-8 fixed w-full shadow-[inset_0_50px_50px_-10px_rgba(255,255,255,0.9)] z-[9999] translation-all duration-300 bg-white"
            style={{marginLeft: marginLeft}}
        >
            <div
                className="w-auto hidden md:block max-w-4xl flex-row items-center gap-2"
            >
                <Tooltip title="Chat selected" placement="bottom">
                <text
                    className="text-gray-800 text-md font-semibold whitespace-nowrap text-overflow-ellipsis overflow-x-hidden flex-grow justify-start"
                    >
                        {currentChatName}
                </text>
                </Tooltip>
            </div>
            <div
                className="w-auto"    
            >
                <div className="flex flex-row gap-2">
                    <div
                        className="flex flex-col justify-center items-center gap-1 relative"
                        onMouseLeave={handleMouseLeave}
                        onMouseEnter={handleMouseEnter}
                    >
                        {/* Button to toggle the menu */}
                        <button
                            className={`flex flex-row gap-1 justify-center items-center text-gray-500 text-md text-center whitespace-nowrap rounded-lg p-2 ${
                                model === 'smart' 
                                ? 'animated-gradient bg-gradient-to-r from-emerald-100 via-green-200 to-emerald-100 hover:from-emerald-50 hover:via-green-100 hover:to-emerald-50' 
                                : 'bg-gray-200 hover:bg-gray-100'
                            }
                                ${sparkaiSidebarOpen ? 'hidden md:flex' : ''}
                            `}
                            onClick={() => setModelMenuOpen(!modelMenuOpen)}
                        >
                            <text
                                className={`text-md font-semibold ${model === 'smart' ? 'bg-gradient-to-r from-emerald-600 to-green-500 bg-clip-text text-transparent' : 'text-gray-500'}`}
                            >{ model === 'smart' ? 'SparkAI Pro' : 'SparkAI mini' }</text>
                            { model==='smart' && <FaBolt className="w-4 h-4 text-green-500" />}
                            <ChevronDownIcon className="w-5 h-5" />
                        </button>

                        {/* Dropdown Menu */}
                        {modelMenuOpen && (
                            <div
                                className="absolute top-full mt-2 left-0 w-80 drop-shadow-md rounded-lg p-3 z-10 border-2 border-gray-200 bg-white flex flex-col gap-1"
                            >
                                <span
                                    className="text-gray-500 text-sm font-semibold px-2"
                                >
                                    Model
                                </span>
                                <button
                                    disabled={messageIsLoading}
                                    className="text-left hover:bg-gray-100 rounded-lg p-2"
                                    onClick={() => {
                                        console.log('Button clicked - fast');
                                        handleModelChange('fast');
                                    }}
                                >
                                    <div
                                        className="flex flex-col gap-1"
                                    >
                                        <text className="text-gray-600">SparkAI mini</text>
                                        <text className="text-xs text-gray-400">Quick reasoning, less accurate. Faster.</text>
                                    </div>
                                </button>
                                <button
                                    disabled={messageIsLoading}
                                    className="text-left rounded-lg p-2 animated-gradient bg-gradient-to-r from-emerald-50 via-green-100 to-emerald-50 hover:from-emerald-25 hover:via-green-50 hover:to-emerald-25"
                                    onClick={() => {
                                        console.log('Button clicked - smart');
                                        handleModelChange('smart');
                                    }}
                                >
                                    <div
                                        className="flex flex-col gap-1"
                                    >
                                        <div className="flex flex-row gap-1 items-center">
                                            <text className="text-gray-600 bg-gradient-to-r from-emerald-600 to-green-500 bg-clip-text text-transparent font-semibold">
                                                SparkAI Pro
                                            </text>
                                            <FaBolt className="w-4 h-4 text-green-500" />
                                        </div>
                                        <text className="text-xs bg-gradient-to-r from-emerald-600 to-green-500 bg-clip-text text-transparent font-semibold">
                                            Superior reasoning, improved citing. Slower.
                                        </text>
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Share Button and Menu */}
                    <div 
                        className="flex flex-col justify-center items-center gap-1 relative"
                        ref={shareMenuRef}
                    >
                        <button
                            className={`flex flex-row gap-1 justify-center items-center text-gray-500 text-md text-center whitespace-nowrap rounded-lg p-2 ${
                                isShared ? 'bg-green-100 hover:bg-green-200' : 'bg-gray-200 hover:bg-gray-100'
                            } transition-all duration-200`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleShareClick();
                            }}
                        >
                            <ShareIcon className={`w-5 h-5 ${isShared ? 'text-green-600' : ''}`} />
                            <text className={`text-md font-semibold ${isShared ? 'text-green-600' : ''}`}>
                                {isShared ? 'Shared' : 'Share'}
                            </text>
                        </button>

                        <AnimatePresence>
                            {shareMenuOpen && (
                                <motion.div 
                                    initial={{ opacity: 0, y: -20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.2, ease: "easeOut" }}
                                    className="absolute top-full mt-2 right-0 w-80 drop-shadow-md rounded-lg p-3 z-10 border-2 border-gray-200 bg-white flex flex-col gap-3"
                                    onMouseLeave={() => {
                                        setTimeout(() => {
                                            setShareMenuOpen(false);
                                            setCopied(false);
                                        }, 300);
                                    }}
                                    onMouseEnter={() => {
                                        if (closeTimeout) {
                                            clearTimeout(closeTimeout);
                                        }
                                    }}
                                >
                                    <div className="flex flex-row justify-between items-center">
                                        <span className="text-gray-500 text-sm font-semibold">
                                            Share this chat
                                        </span>
                                        {isShared && (
                                            <button
                                                onClick={handleUnshare}
                                                className="text-xs text-red-500 hover:text-red-600"
                                            >
                                                Stop sharing
                                            </button>
                                        )}
                                    </div>
                                    {shareError && (
                                        <div className="text-red-500 text-xs">
                                            {shareError}
                                        </div>
                                    )}
                                    <div className="flex flex-row gap-2">
                                        <input
                                            type="text"
                                            value={shareUrl}
                                            readOnly
                                            className="flex-grow p-2 bg-gray-100 rounded-lg text-sm text-gray-600"
                                        />
                                        <button
                                            onClick={copyToClipboard}
                                            className="px-3 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-all duration-200"
                                        >
                                            {copied ? 'Copied!' : 'Copy'}
                                        </button>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
            {!isSmallScreen &&
                <div 
                    className="flex flex-row gap-2 flex-grow justify-end items-center fixed right-12"
                >
                    <img
                        className="w-5 h-5"
                        src={SparkAI_logo} alt="SparkAI Logo"
                    />
                    <text
                        className="text-2xl font-semibold"
                    >
                        SparkAI
                    </text>
                </div>
            }
        </div>
    )
}