import SparkleLogo from '../assets/SparkleLogo.png'

import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid'

import React from 'react'


export default function SparkleTopHeader() {

    const [ammo, setAmmo] = React.useState(0)

    return (
        <div
            className="flex flex-col justify-between items-center w-full h-full"
        >
            <div
                className="flex flex-row justify-evenly items-center w-full"
                >
                <button
                    className="flex flex-row justify-center items-center gap-2 bg-emerald-200 text-emerald-400 rounded-md p-2"
                    onClick={() => window.open('/chat', '_self')}
                    >
                    <ArrowUturnLeftIcon
                        className="h-5 w-5 text-emerald-700"
                    />
                </button>
                <img
                    src={SparkleLogo}
                    alt="Sparkle Logo"
                    className="h-5 w-25"
                    />
                <button
                    className="flex flex-row justify-center items-center gap-2 bg-emerald-200 text-emerald-400 rounded-md p-2"
                    >
                    <QuestionMarkCircleIcon
                        className="h-5 w-5 text-emerald-700"
                    />
                </button>
            </div>
            <div
                className="flex flex-row justify-center items-center w-6/12 md:w-1/3 rounded-b-md p-1"
            >
                <text
                    className="text-xs font-semibold text-center text-emerald-700"
                    >
                    {ammo === 0 ? "¡Haz tu primera pregunta de hoy!" : `Has hecho {ammo} preguntas hoy`}
                </text>
            </div>
        </div>
    )
}