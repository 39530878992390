import axios from 'axios';

// Set axios base headers
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = "c46801db-29ee-408c-bf24-6b16ba8131ac";

/**
 * Call SparkAI Model to get a response
 * 
 * @param {string} query The query to send to the model
 * @param {Array} history The chat history object
 * @param {string} model The AI model to use
 * @param {string} conversation_id The ID of the conversation
 * 
 * @returns {Promise<Object>} The model's response data
 */
export async function SparkAICall(query, history, model, conversation_id) {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + '/api/ia/sparkai/chat', 
            {
                query,
                history,
                conversation_id,
                model,
            }
        );
        console.log('[SparkAICall] Response:', response);
        return response.data; // Return the relevant data from the response
    } catch (error) {
        console.error('[SparkAICall] Error:', error);
        throw error.response?.data || error; // Throw the error for the caller to handle
    }
}


/**
 * Create a chat name based on the query
 * 
 * @param {string} query The query to send to the model
 * 
 * @returns {Promise<string>} The chat name
 */
export async function createChatName(query) {
    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + '/api/ia/get_chat_name', 
            { first_message: query }
        );
        console.log('[createChatName] response: ', response);
        return response.data; // Ensure you return the actual data here
    } catch (error) {
        console.error('[createChatName] error: ', error);
        throw error.response; // Throw the error so it can be caught properly
    }
}

/**
 * Create a chat conversation
 * 
 * @param {string} userId The ID of the user
 * @param {string} chatName The name of the chat conversation
 * 
 * @returns {Promise<Object>} The chat ID wrapped in an object { chatId: string }
 */
export async function createConversation(userId, chatName) {
    console.log('[createConversation] userId: ', userId);
    console.log('[createConversation] chatName: ', chatName);

    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + '/api/chats', 
            {
                user_id: userId,
                conversation_name: chatName
            }
        );
        const chatId = response.data.chat_id; // Extract chat ID from response
        console.log('[createConversation] Created chatId:', chatId);
        return { chatId }; // Return chatId wrapped in an object
    } catch (error) {
        console.error('[createConversation] Error:', error);
        throw error.response?.data || error; // Throw error for caller to handle
    }
}
