import { Drawer } from '@mui/material';
import React from 'react';
import SourcesDrawerList from './SourcesDrawerList';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { UserContext } from '../pages/Chat';

export default function SourcesDrawer({ isOpen, drawerSize, drawerMargin, closeSourcesDrawer }) {

    // if is on mobile, set drawerSize to 10
    if (window.innerWidth < 768) {
        drawerSize = window.innerWidth;
    }

    const { messageIdForSources, setMessageIdForSources } = React.useContext(UserContext);
    const [ thisMessageSelected, setThisMessageSelected ] = React.useState(true);
    const { messageIsLoading } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);
    
    /**
     * Close the sources drawer and messageId when current chat changes
     */
    React.useEffect(() => {
        closeSourcesDrawer();
        setMessageIdForSources(null);
    }, [currentChatId, messageIsLoading]);

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={isOpen}
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    boxShadow: 'none', // Remove the shadow
                    border: 'none', // Remove the border if there's any
                    backgroundColor: 'transparent', // Transparent background
                    zIndex: 99999, // Set the z-index to 9999
                },
            }}
        >
            <div
                className={`flex flex-col bg-white h-screen rounded-md m-[${drawerMargin}] persistent-drawer z-[1001] fixed top-0 right-0 border-2 border-gray-100`}
                style={{ minWidth: drawerSize}}
            >
                <div
                    className="flex flex-row justify-between items-center gap-2 w-full border-b-2 border-gray-100 py-3 px-4"
                >
                    <text
                        className="text-md font-semibold text-gray-700"
                    >
                        More relevant papers
                    </text>
                    <XMarkIcon
                        className="w-6 h-6 text-gray-700 cursor-pointer"
                        onClick={closeSourcesDrawer}
                    />
                </div>
                <div
                    className="flex flex-col gap-2 overflow-y-auto p-4"
                >
                    <div
                        className="flex flex-row justify-between items-center gap-2 w-full bg-gray-100 rounded-md pt-2"    
                    >
                        <button
                            className={`w-1/2 h-10 text-gray-700 font-semibold ${thisMessageSelected ? 'bg-white' : 'bg-gray-100'} rounded-tr-lg`}
                            onClick={() => setThisMessageSelected(true)}
                        >
                            This message
                        </button>
                        <button
                            className={`w-1/2 h-10 text-gray-700 font-semibold ${!thisMessageSelected ? 'bg-white' : 'bg-gray-100'} rounded-tl-lg`}
                            onClick={() => setThisMessageSelected(false)}
                        >
                            All references
                        </button>
                    </div>
                    <SourcesDrawerList width={drawerSize} messageId={messageIdForSources} thisMessageSelected={thisMessageSelected} chatId={currentChatId} isOpen={isOpen} />
                </div>
            </div>
        </Drawer>
    );
}