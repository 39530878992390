import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const checkAuthStatus = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/auth/protected`, { withCredentials: true });
    return response.data.user;
  } catch (error) {
    console.error('Auth check failed:', error);
    return null;
  }
};

export const fetchUserData = async (email) => {
  try {
    const response = await axios.get(`${API_URL}/api/users/mail/${email}`);
    return response.data[0];
  } catch (error) {
    console.error('Failed to fetch user data:', error);
    throw error;
  }
}; 