import { LuAward } from "react-icons/lu";
import React from 'react';
import { CircularProgress } from "@mui/joy";
import { IoSend } from "react-icons/io5";

import axios from 'axios';
import { UserContext } from '../pages/Chat';
import { motion } from "framer-motion";

import { calcMaxInputLength } from '../utils/utils';
import { SparkAICall } from "./helpers";


export default function ChatInput( {enableChatInput} ) {
    const [chatInput, setChatInput] = React.useState('');
    const { messages, setMessages } = React.useContext(UserContext);
    const { messageIsLoading, setMessageIsLoading } = React.useContext(UserContext);
    const { messageIsLoadingDetails, setMessageIsLoadingDetails } = React.useContext(UserContext);
    const { currentChatId } = React.useContext(UserContext);
    const { openSources, setOpenSources } = React.useContext(UserContext);

    const { sources, setSources } = React.useContext(UserContext);

    const { model } = React.useContext(UserContext);

    const areSourcesEmpty = () => {
        return !sources.length
    }

    React.useEffect(() => {
        let interval;
      
        interval = setInterval(() => {
            console.log('Checking...');
        }, 3000); // Run every 2.5 seconds
      
        return () => clearInterval(interval); // Clear the interval when the component unmounts
      }, [messages, currentChatId]);

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (messageIsLoading) {
            return;
        }

        // Validate the input length
        if (calcMaxInputLength(model, chatInput.length)) {
            alert(`Your message is too long. Please try again with a shorter message. Length: ${chatInput.length}`);
            return;
        }
    
        setMessageIsLoading(true); // Set loading state
        setChatInput(''); // Clear input field
    
        try {
            // Add the user message to the messages array
            const updatedMessages = [
                ...messages,
                { role_id: 1, content: chatInput },
            ];
            setMessages(updatedMessages);
    
            // Call the SparkAI model
            const sparkaiCallResult = await SparkAICall(chatInput, updatedMessages, model, currentChatId);
            console.log('[handleSubmit] SparkAI call result:', sparkaiCallResult);

            if (sparkaiCallResult.error){
                return;
            }
    
            // Append the AI response to the messages array
            setMessages(prevMessages => [
                ...prevMessages,
                { role_id: 2, content: sparkaiCallResult.text },
            ]);
        } catch (error) {
            console.error('[handleSubmit] Error:', error);
            alert('An error occurred while processing your message. Please try again.');
        } finally {
            setMessageIsLoading(false); // Reset loading state
        }
    };

    const [textareaHeight, setTextareaHeight] = React.useState(50); // Default height of the textarea
    const textareaRef = React.useRef(null);

    React.useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height to calculate correct scrollHeight
            const newHeight = textareaRef.current.scrollHeight;
            // Cap the height at 150px
            if (newHeight > 125) {
                textareaRef.current.style.height = "125px"; // Maximum height
                textareaRef.current.style.overflowY = "auto"; // Add scrollbar when exceeding max height
                textareaRef.current.style.borderRadius = "25"; // Round the corners
                setTextareaHeight(150); // Update height state for animation
            } else {
                textareaRef.current.style.height = `${newHeight}px`; // Allow auto-resizing below 150px
                textareaRef.current.style.overflowY = "hidden"; // Hide scrollbar when below max height
                textareaRef.current.style.borderRadius = "25"; // Round the corners
                setTextareaHeight(newHeight); // Update height state for animation
            }
        }
    }, [chatInput]);    

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Prevent new line on Enter
            handleSubmit(e); // Trigger form submission
        }
    };
    

    return (
        <>
        { enableChatInput &&
            <div className="flex fixed bottom-0 w-full shadow-md hover:scale-[1.02] transition-all duration-300"

            >
            <form
                className="w-full flex justify-center"
                onSubmit={handleSubmit}
            >
                <motion.textarea required
                    ref={textareaRef}
                    className="input-chat w-4/6 md:w-5/12 relative bottom-8 placeholder-gray-500 hover:bg-gray-100 transition-all duration-300"
                    value={chatInput}
                    onChange={(e) => setChatInput(e.target.value)}
                    startDecorator={<LuAward/>}
                    variant="outline" placeholder="Ask anything"
                    onKeyDown={handleKeyDown} // Listen for Enter key
                    animate={{ height: textareaHeight }} // Animate height dynamically
                    disabled={messageIsLoading}
                />
                <div
                    className="flex justify-center items-center relative right-14 bottom-8"
                >
                    <button
                        className="input-button"
                        type='submit'
                        disabled={messageIsLoading}>
                            {messageIsLoading ? 
                            <CircularProgress 
                            variant='plain'
                            thickness={1}
                            size="sm"
                            /> : 
                            <IoSend
                                className="text-gray-600 w-6 h-6"
                            />
                        }
                    </button>
                </div>
            </form>
        </div>
        }
        </>
    )
}