import { Skeleton } from "@mui/material";
import { GoBookmark } from "react-icons/go";
import { GoBookmarkFill } from "react-icons/go";
import { GoBookmarkSlashFill } from "react-icons/go";
import { FiShare2 } from "react-icons/fi";

import { LuQuote } from "react-icons/lu";

import React from 'react';
import { LambdaContext } from '../pages/Lambda';


const BookmarkState = {
    UNBOOKMARKED: 'unbookmarked',
    BOOKMARKED: 'bookmarked',
    BOOKMARKED_FOCUS: 'bookmarked_focus'
};

function ArticleCard({ article, number }) {

    const [intelligentAbstract, setIntelligentAbstract] = React.useState(false);
    const [bookmarkedState, setBookmarkedState] = React.useState(BookmarkState.UNBOOKMARKED);
    const [abstract, setAbstract] = React.useState(article.abstract);
    const [keyExtract, setKeyExtract] = React.useState(article.keyExtract);

    React.useEffect(() => {
        if (!abstract || abstract === '')
            setAbstract('No abstract available. We are sorry for the inconvenience.');

        if (!keyExtract || keyExtract === '')
            setKeyExtract('No intelligent extract available. We are sorry for the inconvenience.');
    }, [abstract, keyExtract]);

    return (
        <div
            className={`flex flex-col w-full justify-center items-start p-4 flex-wrap gap-1
                `}
        >
            <div
                className='flex flex-row w-full justify-between items-start'
            >
                <div
                    className='flex flex-row justify-start items-center w-full gap-2'
                >
                    <div
                        className="flex justify-center items-center w-5 h-5 bg-gray-200 rounded-full text-xs"
                    >
                        {number + 1}
                    </div>
                    <p
                        className="text-lg font-semibold text-gray-800"
                    >
                        {article.paper_title}
                    </p>
                </div>
                <div
                    className="flex flex-row justify-center items-center text-xs text-gray-500"
                >
                    {article.year}
                </div>
            </div>
            <div
                className="flex flex-row justify-start items-center text-xs text-gray-500"
            >
                {article.authors}
            </div>
            <div
                className='flex flex-col w-full'
            >
                <div
                    className="flex flex-row gap-2 text-sm font-semibold bg-gray-200 px-3 py-1
                    rounded-t-md w-[300px] border-b-2 border-gray-400 cursor-pointer"
                >
                    <div
                        className='flex hover:bg-gray-300 w-auto justify-center items-center'
                        onClick={() => setIntelligentAbstract(false)}
                    >
                        Abstract
                    </div>
                    <div
                        className='border-l-2 border-gray-400 h-auto'
                    />
                    <div
                        className='flex hover:bg-gray-300 w-auto justify-center items-center'
                        onClick={() => setIntelligentAbstract(true)}
                    >
                        Intelligent Abstract
                    </div>
                </div>
                <div
                    className='flex flex-col w-full justify-center items-start gap-1 bg-gray-200 p-2 rounded-b-md rounded-tr-md'
                >
                    <p
                        className="text-sm text-justify"
                    >
                        {intelligentAbstract ? keyExtract : abstract}
                    </p>
                </div>
            </div>
            <div
                className='flex flex-row w-full justify-between items-center'
            >
                <div
                    className='flex flex-row text-xs gap-1'
                >
                    <p
                        className="font-semibold"
                    >
                        {article.citations}
                    </p>
                    <p
                        className="text-gray-500"
                    >
                        citations
                    </p>
                </div>
                <div
                    className='flex flex-row gap-2'
                >
                    <button
                        className="flex justify-center items-center"
                        onMouseEnter={() => { if (bookmarkedState === BookmarkState.BOOKMARKED) setBookmarkedState(BookmarkState.BOOKMARKED_FOCUS) }}
                        onMouseLeave={() => { if (bookmarkedState === BookmarkState.BOOKMARKED_FOCUS) setBookmarkedState(BookmarkState.BOOKMARKED) }}
                    >
                        {bookmarkedState === BookmarkState.BOOKMARKED ? (
                            <GoBookmarkFill
                                className="text-gray-500 h-6 w-6 cursor-pointer"
                            />
                        ) : bookmarkedState === BookmarkState.UNBOOKMARKED ? (
                            <GoBookmark
                                className="text-gray-500 h-6 w-6 cursor-pointer"
                                onClick={() => setBookmarkedState(BookmarkState.BOOKMARKED)}
                            />
                        ) : (
                            <GoBookmarkSlashFill
                                className="text-gray-500 h-6 w-6 cursor-pointer"
                                onClick={() => setBookmarkedState(BookmarkState.UNBOOKMARKED)}
                            />
                        )}
                    </button>
                    
                    <LuQuote
                        className="text-gray-500 h-6 w-6 cursor-pointer"
                    />
                    <FiShare2
                        className="text-gray-500 h-6 w-6 cursor-pointer"
                    />
                </div>
            </div>
        </div>
    )
}

function ArticleCardList({ articles }) {
    return (
        <div
            className="flex flex-col w-full gap-3 justify-center items-center"
        >
            {articles.map((article, index, keyExtract) => (
                <ArticleCard key={index} number={index} article={article}/>
            ))}
        </div>
    )
}

export default function ArticlesSection({ articles }) {

    const { isLoading } = React.useContext(LambdaContext);
    
    return (
        <div
            className="flex flex-col w-full justify-center items-center gap-2"
        >
            <div
                className='flex flex-row justify-between items-center w-full'
            >
                <p
                    className="text-md font-semibold"
                >
                    Relevant Articles
                </p>
                <p
                    className="text-sm text-gray-400"
                >
                    {articles.length} articles
                </p>
            </div>
            <hr
                className="w-full border border-gray-200"
            />
            {isLoading ? (
                <div
                    className="flex flex-col w-full gap-2"
                >
                    <Skeleton
                        variant="rectangular"
                        width={900}
                        height={300}
                        className="rounded-xl"
                    />
                    <Skeleton
                        variant="rectangular"
                        width={900}
                        height={300}
                        className="rounded-xl"
                    />
                    <Skeleton
                        variant="rectangular"
                        width={900}
                        height={300}
                        className="rounded-xl"
                    />
                </div>
            ) : (
                <div
                    className="flex flex-col w-full justify-center items-center"
                >
                    <ArticleCardList articles={articles} />
                </div>
            )}
        </div>
    )
}