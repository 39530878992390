import axios from 'axios';

// Set axios base headers
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = "c46801db-29ee-408c-bf24-6b16ba8131ac";

/**
 * Get word of today from the API
 * 
 * @returns {Promise<string>} The word of the day
 */

export async function getWordOfTheDay() {
    const url = process.env.REACT_APP_API_URL + '/api/sparkle/word/';
    console.log('[GetWordOfTheDay] URL:', url);
    const response = await axios.get(url)
    .catch((error) => {
        console.error('[GetWordOfTheDay] Error fetching word of the day:', error);
        return null;
    });

    console.log('[GetWordOfTheDay] Response:', response);

    let word = null;

    if (!response)
        return null;
     else if (response.data.status === 'success') {
        word = {
            word: response.data.word
        }
    } else {
        word = null;
    }

    return word;
}


/**
 * Request a new word of the day from the API
 * 
 * @returns {Promise<string>} The new word of the day
 */

async function requestNewWordOfTheDay() {
    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/sparkle/word')
    .catch((error) => {
        console.error('Error fetching new word of the day:', error);
        return null;
    });

    if (!response)
        return null;
    if (response?.data?.status === 'success') {
        return {
            word: response.data.word
        }
    } else {
        return null;
    }
}

/**
 * Get the number of people that have guessed the word of the day
 * 
 * @returns {Promise<number>} The number of people that have guessed the word of the day
 */

export async function getPeopleGuessed() {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api/sparkle/guessed')
    .catch((error) => {
        console.error('Error fetching people guessed:', error);
        return null;
    });

    if (!response)
        return null;
    if (response?.data?.status === 'success') {
        return {
            nPeopleGuessed: response.data.nPeopleGuessed
        }
    } else {
        return null;
    }
}


/**
 * Create a new guessing (word)
 * 
 * Recieves a word and returns a word object without any guess like this:
 * [
 *    {
 *      letter: 'a',
 *      status: 'not-tried-letter'
 *    },
 *    {
 *      letter: 'b',
 *      status: 'not-tried-letter'
 *    },  
 * ]
 * @param {string} word The word to guess
 * 
 * @returns {Promise<object>} The word object
 */

export async function createNewGuessing(word, hasWon = false) {
    let guessing = [];
    for (let i = 0; i < word.length; i++) {
        guessing.push({
            letter: word[i].toUpperCase(),
            status: hasWon ? 'correct-letter' : 'not-tried-letter'
        });
    }
    return guessing;
}

/**
 * Guess a word
 * 
 * It takes a new guessing string and the correct word and returns an updated guessing with
 * status like this:
 * - 'wrong-letter' for a letter that is not in the correct word
 * - 'correct-letter' for a letter that is in the correct word and in the correct position
 * - 'bad-placed-letter' for a letter that is in the correct word but in the wrong position
 * 
 * Rules:
 * If a letter is in the correct position, it is marked as 'correct-letter'
 * If a letter is not in the correct word, it is marked as 'wrong-letter'
 * If a letter is in the correct word but has appeared more than the number of times it appears in the correct word, it is marked as 'wrong-letter'
 * If a letter is in the correct word but has appeared less than the number of times it appears in the correct word, it is marked as 'bad-placed-letter'
 * 
 * @param {string} guessing The current guessing
 * 
 * @param {string} word The correct word
 * 
 * @returns {Promise<object>} The updated guessing
 */

export async function guessWord(guessing, word) {
    // Ensure both are same length (as in Wordle)
    console.log('Args:', guessing, word);
    if (guessing.length !== word.length) {
        return {
            guessing: "LENGTH_ERROR",
            hasWon: false
        }
    }
    
    // Convert both to uppercase (if game logic is case-insensitive)
    let guessArr = guessing.toUpperCase().split('');
    let wordArr = word.toUpperCase().split('');

    // remove accents and replace them with the letter without accent
    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    guessArr = removeAccents(guessArr.join('')).split('');
    wordArr = removeAccents(wordArr.join('')).split('');
    
    // Frequency map of letters in the correct word
    const frequency = {};
    for (const letter of wordArr) {
        frequency[letter] = (frequency[letter] || 0) + 1;
    }
    
    // Initialize result array
    const result = guessArr.map(letter => ({ letter, status: 'wrong-letter' }));
    
    // First pass: identify correct letters
    for (let i = 0; i < guessArr.length; i++) {
        if (guessArr[i] === wordArr[i]) {
        result[i].status = 'correct-letter';
        // Decrement frequency since this exact position matches
        frequency[guessArr[i]] -= 1;
        }
    }
    
    // Second pass: identify misplaced or wrong letters
    for (let i = 0; i < guessArr.length; i++) {
        const letter = guessArr[i];
        if (result[i].status === 'correct-letter') {
        // Already processed
        continue;
        }
    
        // If letter appears in the word but still available in frequency
        if (frequency[letter] && frequency[letter] > 0) {
        result[i].status = 'bad-placed-letter';
        frequency[letter] -= 1;
        } else {
        // If no more occurrences of this letter left in the word
        // or it simply doesn't exist in the word
        result[i].status = 'wrong-letter';
        }
    }
    
    return {
        guessing: result,
        hasWon: result.every(letter => letter.status === 'correct-letter')
    };
}

/**
 * Submit a guess for the word of the day
 * 
 * @param {string} guess The user's guess
 * @returns {Promise<boolean>} Whether the user's guess is correct
 */
export async function submitGuess(guess) {
    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/sparkle/guess', { guess })
    .catch((error) => {
        console.error('Error submitting guess:', error);
        return null;
    });

    if (!response.data)
        return null;
    if (response?.data?.status === 'success') {
        return {
            aiMessage: response.data.aiMessage,
            correct: response.data.correct
        }
    } else {
        return null;
    }
}

/**
 * Get the user's profile by using the cookie
 * 
 * @returns {Promise<object>} The user's profile
 */

export async function getUserProfile() {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api/users/profile')
    .catch((error) => {
        console.error('Error fetching user profile:', error);
        return null;
    });

    if (!response)
        return null;
    if (response?.data?.status === 'success') {
        return {
            profile: response.data.profile,
        }
    } else {
        return null;
    }
}

/**
 * Update the user's profile by using the cookie
 * 
 * @param {object} profile The user's profile
 * @returns {Promise<object>} The updated user's profile
 */

export async function updateUserProfile(profile) {
    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/users/profile', { profile })
    .catch((error) => {
        console.error('Error updating user profile:', error);
        return null;
    });

    if (!response)
        return null;
    if (response?.data?.status === 'success') {
        return {
            oldProfile: profile,
            newProfile: response.data.profile
        }
    } else {
        return null;
    }
}


/**
 * Send a message to the AI
 * 
 * It will call the API to send a message to the API and return the updated chat history.
 * The endpoint is /api/sparkle/ai/send
 * 
 * The chat history has this format:
 * [
 *   {
 *      role: 'user' | 'model',
 *      parts: string,
 *   },
 *  ...
 * ]
 * 
 * Hint: Use the getBackendChatHistory function to transform the frontend chat history format into the backend format
 * 
 * @param {string} message The message to send
 * 
 * @param {object} chatHistory The chat history
 * 
 * @returns {Promise<object>} The updated chat history
 */
export async function sendMessageToAI(message, chatHistory, randomWord) {
    const backendChatHistory = await getBackendChatHistory(chatHistory, { user: 'user', text: message });

    console.log('[sendMessageToAI] Backend chat history:', backendChatHistory);

    const time = new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    let aiMessage = null;
    let isGuessing = false;
    let status = null;

    const response = await axios.post(process.env.REACT_APP_API_URL + '/api/sparkle/ai/send', { message, chatHistory: backendChatHistory, randomWord })
    .then((response) => {
        console.log('[sendMessageToAI] Response:', response);
        aiMessage = response.data.aiMessage;
        isGuessing = response.data.isGuessing;
        status = response.data.status;
    })
    .catch((error) => {
        console.error('Error sending message to AI:', error);
        return null;
    });


    if (status === 'success') {
        const newChatHistory = [...backendChatHistory, { role: 'Sparkle', parts: aiMessage}];
        return {
            newMessages: await getFrontendChatHistory(newChatHistory),
            isGuessing: isGuessing
        }
    } else {
        return null;
    }
}


/**
 * Gets the frontend chat history format and transforms it into the backend format
 * 
 * The frontend format is:
 * [
 *  {
 *     role: 'user' | 'Sparkle',
 *     text: string,
 *     time: string
 * },
 * ...
 * ]
 */
async function getBackendChatHistory(chatHistory, optionalNewMessage = null) {
    let backendChatHistory = [];
    for (let i = 0; i < chatHistory.length; i++) {
        backendChatHistory.push({
            role: chatHistory[i].user,
            parts: chatHistory[i].text
        });
    }

    if (optionalNewMessage) {
        backendChatHistory.push({
            role: optionalNewMessage.user,
            parts: optionalNewMessage.text
        });
    }

    return backendChatHistory;
}

/**
 * Gets the backend chat history format and transforms it into the frontend format
 * 
 * The backend format is:
 * [
 *  {
 *     role: 'user' | 'Sparkle',
 *     parts: string,
 * },
 * ...
 * ]
 */

async function getFrontendChatHistory(chatHistory) {
    let frontendChatHistory = [];
    for (let i = 0; i < chatHistory.length; i++) {
        frontendChatHistory.push({
            user: chatHistory[i].role,
            text: chatHistory[i].parts
        });
    }

    return frontendChatHistory;
}

/**
 * Autosolve the word of the day
 * Do not call any endpoint, just return the word
 * 
 * This is the structure of the return object:
 * [
 *  {
 *     letter: 'a',
 *     status: 'correct-letter'
 * },
 * ...
 * ]
 * 
 * @param {string} word The word of the day
 * 
 * @returns {Promise<string>} The word
 */
export async function autosolveWord(word) {
    console.log('[AutosolveWord] Word:', word);
    const guessing = [];
    for (let i = 0; i < word.length; i++) {
        guessing.push({
            letter: word[i],
            status: 'correct-letter'
        });
    }
    console.log('[AutosolveWord] Guessing:', guessing);
    return guessing;
}