import jsPDF from 'jspdf';

const generatePDF = async (element) => {
  try {
    const pdf = new jsPDF('p', 'mm', 'a4');
    
    // PDF configuration
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 20;
    const maxWidth = pageWidth - (2 * margin);
    
    // Set default font
    pdf.setFont('helvetica');
    pdf.setFontSize(11);

    // Function to add text with proper line breaks
    const addTextWithWrapping = (text, y) => {
      const lines = pdf.splitTextToSize(text, maxWidth);
      let currentY = y;
      
      for (let line of lines) {
        if (currentY > pageHeight - margin) {
          pdf.addPage();
          currentY = margin;
        }
        pdf.text(line, margin, currentY);
        currentY += 7;
      }
      return currentY;
    };

    let currentY = margin;

    // Process node content
    const processNode = (node) => {
      let textContent = '';
      
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      }
      
      if (node.nodeType === Node.ELEMENT_NODE) {
        // Handle different element types
        if (node.tagName === 'H1' || node.tagName === 'H2' || node.tagName === 'H3') {
          pdf.setFontSize(node.tagName === 'H1' ? 18 : node.tagName === 'H2' ? 16 : 14);
          pdf.setFont('helvetica', 'bold');
          currentY += 10;
        } else if (node.tagName === 'P') {
          pdf.setFontSize(11);
          pdf.setFont('helvetica', 'normal');
          currentY += 5;
        }
        
        // Process child nodes
        Array.from(node.childNodes).forEach(child => {
          const childText = processNode(child);
          if (childText) {
            if (currentY > pageHeight - margin) {
              pdf.addPage();
              currentY = margin;
            }
            currentY = addTextWithWrapping(childText.trim(), currentY);
          }
        });
        
        // Add spacing after headers and paragraphs
        if (['H1', 'H2', 'H3', 'P'].includes(node.tagName)) {
          currentY += 7;
        }
      }
      
      return textContent;
    };

    // Start processing from the main content
    processNode(element);

    // Handle citations
    const citations = element.querySelectorAll('.citation-button');
    citations.forEach(citation => {
      // Add citation numbers as clickable elements
    });

    pdf.save('wetland-restoration-ai-report.pdf');
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error; // Re-throw to handle in the component
  }
};

export default generatePDF; 