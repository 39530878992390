import './App.css';
import Chat from './pages/Chat.js';
import Signin from './pages/Signin.js';
import Signup from './pages/Signup.js';
import Account from './pages/Account.js';
import Focus from './pages/Focus.js';
import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import React from 'react';
import SparkQuery from './pages/SparkQuery.jsx';
import { useTour } from '@reactour/tour';
import axios from 'axios';
import ForgotPassword from './pages/ForgotPassword.jsx';
import ResetPassword from './pages/ResetPassword.jsx';
import Lambda from './pages/Lambda.jsx';
import Sparkle from './pages/Sparkle.jsx';
import HelixLoader from './common_components/HelixLoader.jsx';
import { Test} from './components/ResponseRenderer.jsx';
import Paper from './pages/Paper.jsx';
import SharedChatView from './components/SharedChatView.js';

export const MainUserContext = React.createContext(null);


function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({});

  React.useEffect(() => {
    console.log('URL:', process.env.REACT_APP_API_URL + '/api/users' + userId);
    axios.get(process.env.REACT_APP_API_URL + '/api/users/' + userId).then((response) => {
        setUser(response.data[0]);
        console.log('User:', response.data[0]);
      });
  }, [userId]);

  const { setIsOpen } = useTour();

  React.useEffect(() => {
    if (!loggedIn) {
      return;
    }
    axios.get(process.env.REACT_APP_API_URL + '/api/tours/starter/' + userId)
      .then((response) => {
        if (response.data.length === 0) {
          setIsOpen(true);
        }
        axios.post(process.env.REACT_APP_API_URL + '/api/tours/starter/', {user_id: userId})
          .then((response) => {
            console.log('Tour started:', response.data);
          })
          .catch((error) => {
            console.error('Error starting tour:', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching tour:', error);
      });
  }, [loggedIn, userId, setIsOpen]);

  const openTutorial = () => {
    //setIsOpen(true);
    console.log('Opening tutorial');
  };

  // Implement ban status here. If an user is banned, display a message and a button to return to the home page.
  const [banStatus, setBanStatus] = useState(false);
  const [banMessage, setBanMessage] = useState("");
  
  React.useEffect(() => {
    if (userId) {
      const clientIP = window.sessionStorage.getItem('clientIP') || ''; // You'll need to set this elsewhere
      axios.get(process.env.REACT_APP_API_URL + '/api/bans/' + userId, {
        params: {
          ip: clientIP
        }
      })
        .then((response) => {
          console.log('Ban status:', response.data);
          setBanStatus(response.data.is_banned);
          setBanMessage(response.data.message || "Your account has been suspended.");
          
          if (response.data.is_banned) {
            // If banned, make another request to increment access attempts
            axios.post(process.env.REACT_APP_API_URL + '/api/bans/access/' + userId, {
              ip: clientIP
            }).catch((error) => {
              console.error('Error updating ban access:', error);
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching ban status:', error);
        });
    }
  }, [userId]);

  if (banStatus && loggedIn) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-red-900 to-gray-900 flex flex-col items-center justify-center p-8 text-center">
        <div className="bg-black/30 backdrop-blur-sm p-8 rounded-lg border border-red-500/50 shadow-2xl max-w-2xl w-full">
          <div className="mb-6">
            <svg className="w-20 h-20 mx-auto text-red-500 animate-pulse" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
            </svg>
          </div>
          
          <h1 className="text-4xl font-bold text-red-500 mb-6">Account Suspended</h1>
          
          <div className="space-y-4 text-gray-300 mb-8">
            <p className="text-xl font-semibold">{banMessage}</p>
            <p className="text-sm">
              Your account has been suspended for violating our Terms of Service. This decision was made after a thorough review of your account activity. Please be aware that this suspension may be permanent, and any attempts to create new accounts to circumvent this suspension will result in immediate action. Additionally, consecutive violations may lead to a permanent ban of your IP address, preventing any future access to our services. We take these matters seriously to ensure a safe and respectful environment for all users. If you believe this is an error, please contact support at support@ederspark.com.
            </p>
            <p className="text-xs text-red-400 font-medium">
              Reference ID: {userId} | Suspension Date: {new Date().toLocaleDateString()}
            </p>
          </div>

          <div className="border-t border-red-500/20 pt-6">
            <button 
              onClick={() => {
                setLoggedIn(false);
                setUserId(null);
                setEmail('');
                setUser({});
                window.location.href = 'https://www.ederspark.com';
              }}
              className="bg-red-600 hover:bg-red-700 text-white px-8 py-3 rounded-lg font-semibold transition-all duration-200 transform hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            >
              Return to ederspark.com
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
    <MainUserContext.Provider value={{ userId, setUserId, email, setEmail, loggedIn, setLoggedIn, user, setUser }}>
      <Routes>
          <Route path="*" element={<Chat openTutorial={openTutorial} />} />
          <Route path="/signin" element={<Signin/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/account" element={<Account/>} />
          <Route path="/focus/:chat_id?" element={<Focus/>} />
          <Route path="/lambda" element={<Lambda/>} />
          <Route path="/sparkle" element={<Sparkle/>} />
          <Route path="/forgot-password" element={<ForgotPassword/>} />
          <Route path="/reset-password" element={<ResetPassword/>} />
          <Route path="/test" element={<Test/>} />
          <Route path="/paper/:paper_id" element={<Paper/>} />
          <Route path="/shared/:shareId" element={<SharedChatView />} />
      </Routes>
    </MainUserContext.Provider>
    </>
  );
}

export default App;
